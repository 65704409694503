import api from 'api';

const PATH = '/SysConfig';

const GET_SITE_CONFIG = async (keyFilter) => {
  const { data } = await api.get(`${PATH}/get-site-config`, {
    params: keyFilter,
  });
  return data;
};

// eslint-disable-next-line import/prefer-default-export
export { GET_SITE_CONFIG };
