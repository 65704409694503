import types from './type-actions';

const actions = {
  showLoader: (payload) => {
    return {
      type: types.SHOW_LOADER,
      payload,
    };
  },
};

export default actions;
