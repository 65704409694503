/* eslint-disable */
import React, { useState, useContext, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Arquivo from '../Arquivo';

const ArquivoCategoria = (props) => {
  let arquivoCategoria = props.arquivoCategoria;
  const [arquivos, setArquivos] = useState(arquivoCategoria.arquivos || []);
  const [links, setLinks] = useState(arquivoCategoria.links || []);

  return (
    <div className="col-6 text-left border-right">
      <h4>{arquivoCategoria.nome}</h4>

      <div className="row">
        <div className="col-6">
          <button
            className="btn btn-link"
            onClick={() => {
              arquivoCategoria.arquivos = [...arquivos, {}];

              setArquivos(arquivoCategoria.arquivos);
            }}
          >
            Adicionar arquivo
          </button>
          {(arquivos || []).map((arquivo, index) => {
            return (
              <div key={index}>
                <FontAwesomeIcon
                  icon={faTimes}
                  className="float-right remove"
                  onClick={() => {
                    arquivoCategoria.arquivos = [...arquivos];
                    arquivoCategoria.arquivos.splice(index, 1);

                    setArquivos(arquivoCategoria.arquivos);
                  }}
                ></FontAwesomeIcon>

                <Arquivo
                  arquivo={arquivo}
                  setArquivo={(file) => {
                    arquivoCategoria.arquivos = [...arquivoCategoria.arquivos];
                    arquivoCategoria.arquivos[index] = file;

                    setArquivos(arquivoCategoria.arquivos);
                  }}
                />
              </div>
            );
          })}
        </div>

        <div className="col-6">
          <button
            className="btn btn-link"
            onClick={() => {
              arquivoCategoria.links = links || [];
              arquivoCategoria.links = [...arquivoCategoria.links, {}];

              setLinks(arquivoCategoria.links);
            }}
          >
            Adicionar link
          </button>

          {(links || []).map((link, index) => {
            return (
              <div key={index} className="mb-3">
                <FontAwesomeIcon
                  icon={faTimes}
                  className="float-right remove"
                  onClick={() => {
                    arquivoCategoria.links = [...links];
                    arquivoCategoria.links.splice(index, 1);

                    setLinks(arquivoCategoria.links);
                  }}
                ></FontAwesomeIcon>

                <label className="mb-0">Link: </label>

                <input
                  type="text"
                  className="form-control"
                  value={link.url || ''}
                  onChange={(event) => {
                    let links = [...arquivoCategoria.links];
                    links[index].url = event.target.value;

                    setLinks(links);
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ArquivoCategoria;
