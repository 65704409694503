/* eslint-disable */

import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';

import 'react-vertical-timeline-component/style.min.css';
import actionLoader from '../../store/modules/loader/actions';
import { dateFormat } from '../../helpers/Date.Helper';

import { GET_LIST_HISTORICO_SOLICITACAO } from 'services/Consulta';
import api from 'api';

import './styles.scss';

const ModalHistoric = (props) => {
  let solicitacaoId = props.solicitacaoId;
  const [historico, setHistorico] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      if (solicitacaoId && solicitacaoId > 0) {
        dispatch(actionLoader.showLoader(true));

        const data = await GET_LIST_HISTORICO_SOLICITACAO(props.solicitacaoId);
        setHistorico(data.value);
        dispatch(actionLoader.showLoader(false));
      } else {
        handleClose();
      }
    })();
  }, [solicitacaoId]);

  const handleClose = () => {
    props.onClose();
    setHistorico([]);
  };

  const show = () => {
    return historico && historico.length > 0;
  };

  return (
    <Modal show={show()} onHide={handleClose} className="modal-historic">
      <Modal.Header closeButton className="header">
        <Modal.Title>
          <div>Solicitação {show() && historico[0].solicitacaoId}</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div style={{ textAlign: "center", color: 'red', marginBottom: '10px' }}>
            {(show() && historico[0].usuarioPendencia) ?
              <div>Pendência para: {historico[0].usuarioPendencia}</div> : null
            }
          </div>
        <VerticalTimeline className="vertical-timeline-custom-line">
          {show() &&
            historico.map((item, idx) => {
              return (
                <VerticalTimelineElement
                  key={idx}
                  className="vertical-timeline-element--work"
                  date={dateFormat(item.data, true)}
                >
                  <h3 className="vertical-timeline-element-title">
                    {item.etapa.descricao}
                  </h3>
                  <h4 className="vertical-timeline-element-subtitle">
                    {item.usuario.nome}
                  </h4>
                  {item.observacao && (
                    <p className="vertical-timeline-element-observation">
                      {item.observacao}
                    </p>
                  )}
                </VerticalTimelineElement>
              );
            })}
        </VerticalTimeline>
      </Modal.Body>
      <Modal.Footer className="modal-footer">
        <Button
          variant="primary"
          onClick={handleClose}
          className="button-close"
        >
          FECHAR
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalHistoric;
