import api from 'api';

const PATH = '/PerguntaFrequente';

const GET_LIST_PERGUNTAS = async () => {
  const { data } = await api.get(`${PATH}/get-list`);
  return data;
};

// eslint-disable-next-line import/prefer-default-export
export { GET_LIST_PERGUNTAS };
