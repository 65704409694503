/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIdBadge } from '@fortawesome/free-solid-svg-icons';

import { dateFormat } from '../../helpers/Date.Helper';

import './styles.scss';

const Response = (props) => {
  return (
    <div className="content-response">
      <div className="resposta">
        <div className="nome">
          <FontAwesomeIcon icon={faIdBadge} />
          <strong>{props.resposta.usuario.nome}</strong>
        </div>
        <div className="texto">
          {props.resposta.urlArquivo != null ? (
            <div>
              <p>{props.resposta.resposta}</p>
              <img src={props.resposta.urlArquivo} />
            </div>
          ) : (
            <p>{props.resposta.resposta}</p>
          )}
        </div>
        <div className="data">
          <output>{dateFormat(props.resposta.data)}</output>
        </div>
      </div>
    </div>
  );
};

export default Response;
