import React from 'react';

import HeaderLogin from 'components/Header/HeaderLogin';
import Bottom from 'components/Bottom';
import ContentLogin from './ContentLogin';

const Login = () => {
  return (
    <>
      <HeaderLogin />
      <ContentLogin />
      <Bottom />
    </>
  );
};

export default Login;
