/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Scroll from 'react-scrollbar';

import './styles.scss';

const TipoMaterialLista = (props) => {
  let model = props.model;
  let materiais = props.materiais;

  const [solicitacoes, setSolicitacoes] = useState(model.solicitacoes);

  const updateSolicitacoes = () => {
    setSolicitacoes([...model.solicitacoes]);
  };

  useEffect(() => {
    setSolicitacoes(model.solicitacoes || []);
  }, [model]);

  const addRemoveItem = (event) => {
    if (event.target.checked) {
      const material = materiais.find(
        (obj) => obj.materialComunicacaoId === parseInt(event.target.value)
      );

      const solicitacao = {
        materialComunicacaoId: material.materialComunicacaoId,
        materialComunicacao: material,
      };

      model.solicitacoes.push(solicitacao);
      updateSolicitacoes();
    } else {
      const indexItem = solicitacoes.findIndex(
        (obj) => obj.materialComunicacaoId === parseInt(event.target.value)
      );
      model.solicitacoes.splice(indexItem, 1);
      updateSolicitacoes(solicitacoes);
    }
  };

  const renderMateriais = () => {
    if (!materiais.length) return null;

    return (
      <div className="row">
        {materiais
          .sort((a, b) => a.nome.localeCompare(b.nome))
          .map((material, index) => {
            const selecionado =
              solicitacoes.findIndex(
                (solicitacao) =>
                  solicitacao.materialComunicacaoId ===
                  material.materialComunicacaoId
              ) !== -1;
            return (
              <div key={index} className="col-lg-4 mx-auto">
                {/* <label>
                  <input type="radio" checked={material.materialComunicacaoId === 0} onChange={() => {
                    model.materialComunicacaoId = material.materialComunicacaoId;

                  }} />

                </label> */}

                <div
                  className={`lista-tipo-arte ${
                    selecionado ? 'selecionado' : ''
                  }`}
                >
                  <div
                    className={`header-card ${
                      selecionado ? 'selecionado' : ''
                    }`}
                  >
                    {material.nome}
                  </div>
                  <div className="body-card">
                    <input
                      type="checkbox"
                      name=""
                      id={`check-material-${material.materialComunicacaoId}`}
                      value={material.materialComunicacaoId}
                      onChange={addRemoveItem}
                      checked={
                        solicitacoes.findIndex(
                          (solicitacao) =>
                            solicitacao.materialComunicacaoId ===
                            material.materialComunicacaoId
                        ) !== -1
                      }
                    />

                    <label
                      htmlFor={`check-material-${material.materialComunicacaoId}`}
                    >
                      <img src={material.urlImagem} className="img-fluid" />
                    </label>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  return <>{renderMateriais()}</>;
};

export default TipoMaterialLista;
