import React from 'react';

import PageWithoutHeader from 'components/PageWithoutHeader';
import ContentRecoverPassword from './_Content/ContentRecoverPassword';

const RecoverPassword = () => {
  return (
    <PageWithoutHeader title="ESQUECI MINHA SENHA">
      <ContentRecoverPassword />
    </PageWithoutHeader>
  );
};

export default RecoverPassword;
