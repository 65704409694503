import api from 'api';

const PATH = '/Admin';

const GET_LIMPAR_CACHE = async () => {
  const { data } = await api.get(`${PATH}/get-limpar-cache`);
  return data;
};

const GET_LIST_PAGED_USUARIOS = async (filtros) => {
  const { data } = await api.get(`${PATH}/get-list-paged-usuario`, {
    params: filtros,
  });
  return data;
};

const GET_SELECT_LIST_USUARIO_TIPO = async () => {
  const { data } = await api.get(`${PATH}/get-select-list-usuario-tipo`);
  return data;
};

const GET_ADMIN_LOGIN = async (usuarioId) => {
  const { data } = await api.get(`${PATH}/get-token/${usuarioId}`);
  return data;
};

export {
  GET_ADMIN_LOGIN,
  GET_LIMPAR_CACHE,
  GET_LIST_PAGED_USUARIOS,
  GET_SELECT_LIST_USUARIO_TIPO,
};
