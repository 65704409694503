import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import pt from 'date-fns/locale/pt-BR';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import './styles.scss';

registerLocale('pt', pt);

const DatePickerCustom = ({
  name,
  disabled,
  selected,
  onChange,
  minDate,
  maxDate,
  className,
}) => {
  return (
    <div className="date-picker-custom input-group">
      <DatePicker
        locale="pt"
        name={name}
        dateFormat="dd/MM/yyyy"
        disabled={disabled}
        selected={selected}
        minDate={minDate}
        maxDate={maxDate}
        onChange={(value) => onChange(value, name)}
        className={`form-control ${className}`}
      />
      <div className="input-group-append">
        <span className="input-group-text">
          <FontAwesomeIcon icon={faCalendar} />
        </span>
      </div>
    </div>
  );
};

export default DatePickerCustom;
