import types from './type-actions';

const actions = {
  setCustomer: (payload) => {
    return {
      type: types.SET_CUSTOMER,
      payload,
    };
  },
};

export default actions;
