import api from 'api';

const PATH = '/Etapa';

const GET_SELECT_ETAPA = async () => {
  const { data } = await api.get(`${PATH}/get-select-list`);
  return data;
};

// eslint-disable-next-line import/prefer-default-export
export { GET_SELECT_ETAPA };
