/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useContext } from 'react';
import { Table, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faCheckCircle,
  faDownload,
  faTimes,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';

import { Context } from 'context/AuthContext';
import { GET_IMAGE_ZIP } from 'services/Download';

import './card-item.scss';

const CardItem = ({ produto, selecionado, handler }) => {
  const [showModal, setShowModal] = useState(false);
  const { showLoader } = useContext(Context);

  const downloadImagem = async (id) => {
    const ids = [id];
    showLoader(true);

    const data = await GET_IMAGE_ZIP(ids);

    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'imagens.zip');
    document.body.appendChild(link);
    link.click();
    showLoader(false);
  };

  return (
    <>
      <div className="card" key={produto.bancoImagemId}>
        <div
          className="card-title"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <span
            style={{
              whiteSpace: 'nowrap',
              width: '12em',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {' '}
            {produto.descricao}{' '}
          </span>
        </div>

        <div className="img-card">
          <span
            style={{
              display: selecionado ? 'block' : 'none',
            }}
          >
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{ color: '#28B83B', fontSize: '1.8rem' }}
            />
          </span>
          <img
            className="card-img-top center-block"
            src={produto.urlImagemMiniatura}
            alt="imagem"
          />
        </div>
        <div className="card-body">
          <div className="row justify-content-md-center">
            {/* eslint-disable-next-line */}
            <a
              onClick={() => {
                setShowModal(true);
              }}
              className="btn btn-primary "
            >
              <FontAwesomeIcon icon={faPlus} />
              <span>DETALHES</span>
            </a>
            <div className="col col-md-auto" />
            {/* eslint-disable-next-line */}
            <a
              onClick={(event) => {
                event.stopPropagation('click');
                handler(produto);
              }}
              value="Ativo"
              id="add"
              className="btn btn-primary"
            >
              <FontAwesomeIcon
                icon={selecionado ? faTimesCircle : faCheckCircle}
              />
              <span>{selecionado ? 'EXCLUIR' : 'ADICIONAR'}</span>
            </a>
          </div>
        </div>
      </div>
      {showModal ? (
        <div
          onClick={() => {
            setShowModal(false);
          }}
          className="mb-modal"
        >
          <div
            onClick={(event) => {
              event.stopPropagation('click');
            }}
            className="mb-modal-body"
          >
            <FontAwesomeIcon
              onClick={() => setShowModal(false)}
              style={{
                position: 'absolute',
                top: '20px',
                right: '20px',
                color: '#286DB8',
                cursor: 'pointer',
              }}
              icon={faTimes}
            />

            <div className="thumb-produto">
              <img
                className="imagem-produto"
                src={produto.urlImagemMiniatura}
                alt=""
              />
            </div>
            <div className="descricao-produto" style={{ paddingTop: '40px' }}>
              <h3 style={{ color: '#646464' }}>Informações</h3>

              <Table striped bordered hover>
                <tbody>
                  <tr>
                    <td style={{ color: '#646464', fontWeight: 'bold' }}>
                      Código
                    </td>
                    <td>{produto?.codigo}</td>
                  </tr>
                  <tr>
                    <td style={{ color: '#646464', fontWeight: 'bold' }}>
                      Descrição
                    </td>
                    <td>{produto?.descricao}</td>
                  </tr>
                  <tr>
                    <td style={{ color: '#646464', fontWeight: 'bold' }}>
                      Categoria Imagem
                    </td>
                    <td>{produto?.bancoImagemCategoria.nome}</td>
                  </tr>
                  <tr>
                    <td style={{ color: '#646464', fontWeight: 'bold' }}>
                      Categoria Produto
                    </td>
                    <td>{produto?.produtoCategoria?.nome}</td>
                  </tr>
                  <tr>
                    <td style={{ color: '#646464', fontWeight: 'bold' }}>
                      Produto
                    </td>
                    <td>--</td>
                  </tr>
                  <tr>
                    <td style={{ color: '#646464', fontWeight: 'bold' }}>
                      Situação
                    </td>
                    <td>{produto.ativo ? 'Ativo' : 'Inativo'}</td>
                  </tr>
                </tbody>
              </Table>
              <Button
                onClick={() => {
                  downloadImagem(produto.bancoImagemId);
                }}
                style={{
                  width: '340px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderRadius: '30px',
                }}
              >
                <FontAwesomeIcon className="icon" icon={faDownload} />
                <div style={{ width: '100%' }}>FAZER DOWNLOAD DA IMAGEM</div>
              </Button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default CardItem;
