import React from 'react';

import './styles.scss';

const Brand = (props) => {
  const { white } = props;
  return (
    <img
      className="brand"
      src={
        white
          ? 'https://maxi-midias.s3.us-west-2.amazonaws.com/logo/TradeSobMedida.png'
          : ''
      }
      alt="camil"
    />
  );
};

export default Brand;
