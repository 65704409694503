import api from 'api';

const PATH = '/Pendencia';

const COUNT_PENDENCIA_USUARIO_ATUAL = async () => {
  const { data } = await api.get(`${PATH}/count-pendencia-por-usuario-logado`);
  return data;
};

const GET_LIST_PENDENCIA_USUARIO_ATUAL = async () => {
  const { data } = await api.get(
    `${PATH}/get-list-pendencia-por-usuario-logado`
  );
  return data;
};

export { COUNT_PENDENCIA_USUARIO_ATUAL, GET_LIST_PENDENCIA_USUARIO_ATUAL };
