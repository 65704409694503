import React from 'react';

import PageWithoutHeader from 'components/PageWithoutHeader';
import ContentRecoveryEmailSent from '../_Content/ContentRecoveryEmailSent';

const RecoverPassword = () => {
  return (
    <PageWithoutHeader>
      <ContentRecoveryEmailSent />
    </PageWithoutHeader>
  );
};

export default RecoverPassword;
