import api from 'api';

const PATH = '/LayoutArte';

const GET_LIST_ARTE_FACIL = async (filtros) => {
  const { data } = await api.get(
    `${PATH}/get-list-paged-arte-facil-por-filtro`,
    {
      params: filtros,
    }
  );
  return data;
};
const GET_LAYOUT_ARTE = async (id) => {
  const { data } = await api.get(`${PATH}/get-layout-arte/${id}`);
  return data;
};
const GET_LIST_LAYOUT_ARTE = async (filtros) => {
  const { data } = await api.get(
    `${PATH}/get-list-paged-layout-arte-por-filtro`,
    {
      params: filtros,
    }
  );
  return data;
};

export { GET_LIST_ARTE_FACIL, GET_LAYOUT_ARTE, GET_LIST_LAYOUT_ARTE };
