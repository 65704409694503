import api from 'api';

const PATH = '/ProdutoCategoria';

const GET_LIST_CATEGORIA_FILHAS_NORMALIZADAS = async (superiorIds) => {
  const { data } = await api.get(
    `${PATH}/get-list-categoria-filhas-normalizadas`,
    {
      params: superiorIds,
    }
  );
  return data;
};
const GET_SELECT_LIST_CATEGORIA = async (ids) => {
  const { data } = await api.get(`${PATH}/get-select-list-categoria`, {
    params: ids,
  });
  return data;
};

const GET_SELECT_LIST_MARCA = async () => {
  const { data } = await api.get(`${PATH}/get-select-list-marca`);
  return data;
};

const GET_LIST_MARCA = async () => {
  const { data } = await api.get(`${PATH}/get-list-marca`);
  return data;
};

const GET_LIST_CATEGORIA = async () => {
  const { data } = await api.get(`${PATH}/get-list-categoria`);
  return data;
};

const GET_LIST_GRUPO = async () => {
  const { data } = await api.get(`${PATH}/get-list-grupo`);
  return data;
};

export {
  GET_LIST_CATEGORIA_FILHAS_NORMALIZADAS,
  GET_SELECT_LIST_CATEGORIA,
  GET_SELECT_LIST_MARCA,
  GET_LIST_MARCA,
  GET_LIST_CATEGORIA,
  GET_LIST_GRUPO,
};
