/* eslint-disable */
import api from 'api';

const PATH = '/BancoArte';
const qs = require('qs');

const GET_LIST_BANCO_ARTE = async (filtro) => {
  const { data } = await api.get(
    `${PATH}/get-list-paged-banco-arte-por-filtro`,
    {
      params: { ...filtro, SortDirection: 'DESC' },
      paramsSerializer: (filtroSerializer) => {
        return qs.stringify(filtroSerializer);
      },
    }
  );
  return data;
};

const GET_LIST_LOJA_COM_ARTE_APROVADA = async (filtros) => {
  const { data } = await api.get(
    `${PATH}/get-list-loja-com-arte-aprovada`, {
      params: filtros,
    });
  return data?.value;
};

export { GET_LIST_BANCO_ARTE, GET_LIST_LOJA_COM_ARTE_APROVADA};
