/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-plusplus */
/* eslint-disable no-shadow */
/* eslint-disable prefer-const */

/* eslint-disable */
import React, { useState, useEffect, useContext } from 'react';
import { UncontrolledCollapse } from 'reactstrap';
import { FormGroup, Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faEraser, faPlus } from '@fortawesome/free-solid-svg-icons';

import { Context } from 'context/AuthContext';

import { GET_LIST_CAMPANHA, GET_LIST_MATERIAL } from 'services/Solicitacao';
import {
  GET_LIST_MARCA, GET_LIST_CATEGORIA
} from 'services/ProdutoCategoria';
import DatePicker from 'components/_base/DatePicker';

import './styles.scss';
import { GET_LIST_LOJA_COM_ARTE_APROVADA } from 'services/BancoArte';

const FilterArtes = (props) => {
  const { showLoader } = useContext(Context);
  const [showModal, setShowModal] = useState(false);

  const [categorias, setCategorias] = useState([]);
  const [categoriasSelecionadas, setCategoriasSelecionadas] = useState([]);

  const [marcas, setMarcas] = useState([]);
  const [marcasSelecionadas, setMarcasSelecionadas] = useState([]);

  const [materialComunicacao, setMaterialComunicacao] = useState([]);
  const [materialComunicacaoSelecionadas, setMaterialComunicacaoSelecionadas] =
    useState([]);

  const [campanhas, setCampanhas] = useState([]);
  const [campanhasSelecionados, setCampanhasSelecionados] = useState([]);

  const [solicitacaoId, setSolicitacaoId] = useState('');
  const [codigo, setCodigo] = useState('');

  const [search, setSearch] = useState('');
  const [cliente, setCliente] = useState(null);
  const [clientSelected, setClientSelected] = useState(null);
  const [clientsList, setClientsList] = useState([]);

  const [dataInicio, setDataInicio] = useState('');
  const [dataFim, setDataFim] = useState('');

  // ============================================================================================================================
  // Effects
  useEffect(() => {
    showLoader(true);

    (async () => {
      const listaCategoria = await GET_LIST_CATEGORIA();
      setCategorias(listaCategoria.value);
    })();

    (async () => {
      const listaMarca = await GET_LIST_MARCA();
      setMarcas(listaMarca.value);
    })();

    (async () => {
      const data = await GET_LIST_MATERIAL();
      setMaterialComunicacao(data.value);
    })();

    (async () => {
      const data = await GET_LIST_CAMPANHA();
      setCampanhas(data.value);
    })();
    showLoader(false);
  }, []);

  // ============================================================================================================================
  // Event handlers
  function onChangeSolicitacaoId(event) {
    const { value } = event.target;
    setSolicitacaoId(value);
  }

  // ----------------------------------------------------------------------------------------------------------------------------
  function onChangeCodigo(event) {
    const { value } = event.target;
    setCodigo(value);
  }

  // ----------------------------------------------------------------------------------------------------------------------------
  const onSearch = async () => {
    //setLoader(true);
    const data = await GET_LIST_LOJA_COM_ARTE_APROVADA({ codigoCnpjRazaoSocial: search });
    if (data.length === 1) {
      updateCliente(data[0]);
    } else {
      setClientsList(data);
      setShowModal(true);
    }

    //setLoader(false);
  };

  // ----------------------------------------------------------------------------------------------------------------------------
  const onChangeSearchText = (event) => {
    setSearch(event.target.value);
  };

  // ----------------------------------------------------------------------------------------------------------------------------
  const onChangeMaterialComunicacao = (event) => {
    let matComunicacao = materialComunicacaoSelecionadas;

    if (event.target.checked) {
      const item = matComunicacao.find((item) => item === event.target.value);

      if (!item) {
        matComunicacao.push(event.target.value);
      }
    } else {
      const item = matComunicacao.find((item) => item === event.target.value);
      const indexItem = matComunicacao.findIndex(
        (item) => item === event.target.value
      );

      if (item) {
        matComunicacao.splice(indexItem);
      }
    }

    setMaterialComunicacaoSelecionadas(matComunicacao);
  };

  // ----------------------------------------------------------------------------------------------------------------------------
  const onChangeCampanhas = (event) => {
    let campanhas = campanhasSelecionados;

    if (event.target.checked) {
      const item = campanhas.find((item) => item === event.target.value);

      if (!item) {
        campanhas.push(event.target.value);
      }
    } else {
      const item = campanhas.find((item) => item === event.target.value);
      const indexItem = campanhas.findIndex(
        (item) => item === event.target.value
      );

      if (item) {
        campanhas.splice(indexItem);
      }
    }

    setCampanhasSelecionados(campanhas);
  };

  // ----------------------------------------------------------------------------------------------------------------------------
  const onChangeCategoria = (event) => {
    const elementValue = parseInt(event.target.value);
    const $element = event.target;
    let categorias = categoriasSelecionadas;

    if ($element.checked) {
      const itemCategoria = categorias.find((categoria) => categoria === elementValue);

      if (!itemCategoria) {
        categorias = [];
        categorias.push(elementValue);
      }
      setCategoriasSelecionadas(categorias);
    } else {
      categorias = [];
      setCategoriasSelecionadas(categorias);
    }
  };

  // ----------------------------------------------------------------------------------------------------------------------------
  const onChangeMarcas = (event) => {
    const elementValue = parseInt(event.target.value);
    const $element = event.target;
    let marcas = marcasSelecionadas;
    let items = [];

    if ($element.checked) {
      const itemMarca = marcas.find((marca) => marca === elementValue);

      if (!itemMarca) {
        marcas.push(elementValue);
      }
      setMarcasSelecionadas([...marcas]);
    } else {
      let newMarcas = [];

      for (let i = 0; i < marcas.length; i++) {
        if (marcas[i] !== elementValue) {
          newMarcas.push(marcas[i]);
        }
      }

      setMarcasSelecionadas(newMarcas);
    }
  };

  // ----------------------------------------------------------------------------------------------------------------------------
  function onChangeInicio(value) {
    setDataInicio(value?? '');
  }

  // ----------------------------------------------------------------------------------------------------------------------------
  function onChangeFim(value) {
    setDataFim(value?? '');
  }

  // ----------------------------------------------------------------------------------------------------------------------------
  const selectClient = (index) => {
    setClientSelected(index);
    updateCliente(clientsList[index]);
  };

  // ----------------------------------------------------------------------------------------------------------------------------
  const updateCliente = (obj) => {
    setCliente(obj);
    setSearch(obj.razaoSocial);
  };

  // ----------------------------------------------------------------------------------------------------------------------------
  const filterArtes = async () => {
    let clienteId = '';

    if(cliente){
      clienteId = cliente.lojaId;
    }

    await props.filtrar(
      solicitacaoId,
      materialComunicacaoSelecionadas,
      campanhasSelecionados,
      categoriasSelecionadas,
      marcasSelecionadas,
      codigo,
      clienteId,
      dataInicio,
      dataFim
    );
  };

  // ----------------------------------------------------------------------------------------------------------------------------
  const clearArtes = async () => {
    setMaterialComunicacaoSelecionadas([]);
    setCampanhasSelecionados([]);
    setCategoriasSelecionadas([]);
    setMarcasSelecionadas([]);
    setSolicitacaoId('');
    setCodigo('');
    setSearch('');
    setCliente(null);
    setClientSelected(null);
    setDataInicio(null);
    setDataFim(null);

    const items = document.getElementsByTagName('INPUT');

    for (let loop = 0; loop < items.length; loop++) {
      let item = items[loop];
      if ((item.type === 'checkbox' || item.type === 'radio') && item.checked) {
        item.checked = false;
      }
    }

    await props.filtrar('', [], [], [], [], '');
  };

  // ----------------------------------------------------------------------------------------------------------------------------
  const hideModal = () => {
    setShowModal(false);
  };

  // ============================================================================================================================
  // Render
  return (
    <div className="filter-artes">
      <div className="titulo">FILTROS</div>
      <div className="body">
        {/* Solicitacao ----------------------------------------------------------------------------------------------------- */}
        <div className="input-fa">
          <label>Solicitação</label>
          <input
            type="text"
            className="form-control"
            onChange={onChangeSolicitacaoId}
            value={solicitacaoId}
          />
        </div>

        <hr className="fa" />

        {/* Categorias ------------------------------------------------------------------------------------------------------ */}
        <div className="filters">
          <label type="button" className="btn btn-block" id="itensCategoria">
            Categorias{' '}
            <FontAwesomeIcon
              className="float-right"
              style={{ marginLeft: '10px' }}
              icon={faPlus}
            />
          </label>
          <UncontrolledCollapse toggler="#itensCategoria">
            <div>
              {categorias.map((categoria) => {
                return (
                  <div key={categoria.produtoCategoriaId}>
                    <label style={{ fontSize: '14px' }}>
                      <input
                        onChange={onChangeCategoria}
                        value={categoria.produtoCategoriaId}
                        type="radio"
                        name='radio'
                      />{' '}
                      {categoria.nome}
                    </label>
                  </div>
                );
              })}
            </div>
          </UncontrolledCollapse>
        </div>

        <hr className="fa" />
        {/* Marcas ---------------------------------------------------------------------------------------------------------- */}
        <div className="filters">
          <label type="button" className="btn btn-block" id="itensMarca">
            Marcas{' '}
            <FontAwesomeIcon
              className="float-right"
              style={{ marginLeft: '10px' }}
              icon={faPlus}
            />
          </label>
          <UncontrolledCollapse toggler="#itensMarca">
            <div>
              {marcas.filter((marca) => marca.produtoCategoriaSuperiorId == categoriasSelecionadas[0]).map((marca) => {
                return (
                  <div key={marca.produtoCategoriaId}>
                    <label style={{ fontSize: '14px' }}>
                      <input
                        onChange={onChangeMarcas}
                        value={marca.produtoCategoriaId}
                        type="checkbox"
                      />{' '}
                      {marca.nome}
                    </label>
                  </div>
                );
              })}
            </div>
          </UncontrolledCollapse>
        </div>

        <hr className="fa" />

        {/* Material -------------------------------------------------------------------------------------------------------- */}
        <div className="filters">
          <label type="button" className="btn btn-block" id="itensMaterial">
            Material Comunicação{' '}
            <FontAwesomeIcon
              className="float-right"
              style={{ marginLeft: '10px' }}
              icon={faPlus}
            />
          </label>
          <UncontrolledCollapse toggler="#itensMaterial">
            <div>
              {materialComunicacao.map((material) => {
                return (
                  <div>
                    <label style={{ fontSize: '14px' }}>
                      <input
                        onChange={onChangeMaterialComunicacao}
                        type="checkbox"
                        value={material?.materialComunicacaoId}
                      />{' '}
                      {material.nome}
                    </label>
                  </div>
                );
              })}
            </div>
          </UncontrolledCollapse>
        </div>

        <hr className="fa" />

        {/* Campanha -------------------------------------------------------------------------------------------------------- */}
        <div className="filters">
          <label type="button" className="btn btn-block" id="itensCampanha">
            Campanha
            <FontAwesomeIcon className="float-right" icon={faPlus} />
          </label>
          <UncontrolledCollapse toggler="#itensCampanha">
            <div>
              {campanhas.map((campanha) => {
                return (
                  <div>
                    <input
                      onChange={onChangeCampanhas}
                      type="checkbox"
                      value={campanha?.campanhaId}
                    />{' '}
                    {campanha.nome}
                  </div>
                );
              })}
            </div>
          </UncontrolledCollapse>
        </div>

        <hr className="fa" />

        {/* Produto --------------------------------------------------------------------------------------------------------- */}
        <div className="input-fa">
          <label>Nome ou Código do Produto</label>
          <input
            type="text"
            className="form-control"
            onChange={onChangeCodigo}
            value={codigo}
          />
        </div>

        <hr className="fa" />

        {/* Cliente --------------------------------------------------------------------------------------------------------- */}
        <div className="input-fa">
          <label>Cliente</label>
            <div className="input-group " style={{paddingRight: "10px"}}>
              <input
                type="text"
                className="form-control"
                style={{width: "150px"}}
                onChange={onChangeSearchText}
                value={search}
              />
              <div className="input-group-append">
                <span
                  className="input-group-text primary"
                  type="button"
                  onClick={()=>onSearch()}>
                    <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                </span>
              </div>
            </div>
        </div>

        <hr className="fa" />

        {/* Data ------------------------------------------------------------------------------------------------------------ */}
        <div className="input" style={{paddingRight: "0px"}}>
          <label>Criado em</label>
          <DatePicker
            className="filter-data"
            name="dataInicio"
            maxDate={new Date()}
            selected={dataInicio}
            onChange={(item) => onChangeInicio(item)}
          />
          <label style={{ marginTop: "10px" }}>Até</label>
          <DatePicker
            className="filter-data"
            name="dataFim"
            maxDate={new Date()}
            selected={dataFim}
            onChange={(item) => onChangeFim(item)}
          />
        </div>

        <hr className="fa" />

        <div className="buttons">
          <FormGroup className="row">
            <div className=" col-lg-12">
              {/* Botao Filtrar --------------------------------------------------------------------------------------------- */}
              <Button
                onClick={filterArtes}
                className="query-button"
                variant="primary"
              >
                <FontAwesomeIcon icon={faSearch} /> Filtrar
              </Button>
              {/* Botao Limpar ---------------------------------------------------------------------------------------------- */}
              <Button
                onClick={clearArtes}
                className="query-button"
                variant="outline-primary"
              >
                <FontAwesomeIcon icon={faEraser} /> Limpar
              </Button>
            </div>
          </FormGroup>
        </div>
      </div>

      {/* Modal Clientes ==================================================================================================== */}
      <Modal show={showModal} onHide={hideModal} className="modal-busca-loja">
          <Modal.Header closeButton className="header">
            <Modal.Title>Clientes</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Razão Social</th>
                  <th>CNPJ</th>
                  <th>Endereço</th>
                </tr>
              </thead>
              <tbody>
                {clientsList.map((item, index) => {
                  return (
                    <tr
                      className={
                        clientSelected === index
                          ? 'tr-selecao-loja tr-selecionado'
                          : 'tr-selecao-loja'
                      }
                      key={index}
                      onClick={() => selectClient(index)}
                      style={{
                        backgroundColor:
                          clientSelected === index
                            ? 'rgba(40, 109, 184,.5)'
                            : 'inherit',
                      }}
                    >
                      <td>{item.razaoSocial}</td>
                      <td>{item.cnpj}</td>
                      <td>{`${item.logradouro}, ${item.numero} - ${item.cidade?.nome}-${item.cidade?.uf}`}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Modal.Body>
          <Modal.Footer className="modal-footer">
            <Button
              variant="primary"
              onClick={hideModal}
              className="button-close"
            >
              CONCLUIR
            </Button>
          </Modal.Footer>
        </Modal>

    </div >
  );
};

export default FilterArtes;
