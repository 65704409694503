/* eslint-disable */
/* eslint-disable react/button-has-type */
/* eslint-disable object-shorthand */
/* eslint-disable prefer-const */
import React, { useEffect, useState, useContext } from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

import { GET_LIST_MATERIAL_COMUNICACAO_ARTE_FACIL } from 'services/MaterialComunicacao';
import { Context } from 'context/AuthContext';
import { tabs } from '../_constants';

const Tamanho = (props) => {
  const { setActiveTab, model, setModel } = props;
  const { showLoader } = useContext(Context);
  const [material, setMaterial] = useState(null);
  const [materialSelecionado, setMaterialSelecionado] = useState('');
  // const [materialModel, setMaterialModel] = useState(model.solicitacoes);

  useEffect(() => {
    (async () => {
      showLoader(true);
      const result = await GET_LIST_MATERIAL_COMUNICACAO_ARTE_FACIL();
      let materiaisApi = result.value.map((materiais) => {
        const tamanhos = materiais.materialTamanhos.map((item) => {
          return {
            ...item,
          };
        });
        return {
          ...materiais,
          materialTamanhos: tamanhos,
        };
      });
      setMaterial(materiaisApi);

      if (model.solicitacoes) {
        let initMateriaisRascunho = [];
        model.solicitacoes.forEach((item) => {
          item.solicitacaoTamanhos.forEach((tam) => {
            initMateriaisRascunho.push({
              materialComunicacaoId:
                item.materialComunicacao.materialComunicacaoId,
              materialComunicacaoTamanhoId: tam.solicitacaoTamanhoId,
              nome: item.materialComunicacao.nome,
              nomeResumo: item.materialComunicacao.nome,
              largura: tam.largura,
              altura: tam.altura,
              unidadeMedida: tam.unidadeTamanho,
            });
          });
        });
        setMaterialSelecionado(initMateriaisRascunho);
      }

      showLoader(false);
    })();
  }, []);

  const handleChange = (value) => {
    // setMaterialSelecionado()
    const valorNaLista =
      materialSelecionado &&
      materialSelecionado.filter(
        (valorAtual) =>
          valorAtual.materialComunicacaoTamanhoId ===
            value.materialComunicacaoTamanhoId && true
      );
    if (!valorNaLista.length) {
      setMaterialSelecionado([...materialSelecionado, value]);
    } else {
      const removerMaterialSelecionado = materialSelecionado.filter(
        (valorAtual) =>
          valorAtual.materialComunicacaoTamanhoId !==
          value.materialComunicacaoTamanhoId
      );
      setMaterialSelecionado(removerMaterialSelecionado);
    }
  };

  const submitSolicitacoes = () => {

    let solicitacaoEntregaDigital = '';
    let solicitacaoDadosAntiga = '';
    let solicitacaoAcaoPromocionais = '';

    if (model.solicitacoes) {
      if (model.solicitacoes.length) {
        solicitacaoEntregaDigital =
          model.solicitacoes[0].solicitacaoEntregaDigital;
        solicitacaoDadosAntiga = model.solicitacoes[0].solicitacaoDados;
        if (model.solicitacoes[0].solicitacaoAcaoPromocionais)
          solicitacaoAcaoPromocionais =
            model.solicitacoes[0].solicitacaoAcaoPromocionais;
      }
    }
    let todasSolicitacoes = materialSelecionado.map((value) => {
      return {
        materialComunicacaoId: value.materialComunicacaoId,
        imprimir: false,
        entregaTipoId: 4,
        solicitacaoAcaoPromocionais: solicitacaoAcaoPromocionais,
        solicitacaoDados:
          solicitacaoDadosAntiga !== ''
            ? solicitacaoDadosAntiga
            : {
                layoutArteId: model.layoutArteFacil.layoutArteId,
                layoutArte: model.layoutArteFacil,
              },
        solicitacaoTamanhos: [
          {
            solicitacaoTamanhoId: value.materialComunicacaoTamanhoId,
            unidadeTamanho: value.unidadeMedida,
            altura: value.altura,
            largura: value.largura,
          },
        ],
        materialComunicacao: {
          materialComunicacaoId: value.materialComunicacaoId,
          nome: value.nomeResumo,
        },
        solicitacaoEntregaDigital: solicitacaoEntregaDigital,
      };
    });
    todasSolicitacoes = todasSolicitacoes.reduce((acc, curr) => {
      const { materialComunicacaoId: key } = curr;
      const ind = acc
        ? acc.findIndex((el) => el.materialComunicacaoId === key)
        : -1;
      if (ind !== -1) {
        acc[ind].solicitacaoTamanhos = [
          ...acc[ind].solicitacaoTamanhos,
          ...curr.solicitacaoTamanhos,
        ];
      } else {
        acc.push(curr);
      }
      return acc;
    }, []);

    setModel({
      solicitacoes: todasSolicitacoes,
    });
  };

  return (
    <div className="container pt-5">
      <div className="row">
        {material &&
          material.map((item) => {
            return (
              <div className="col-md-4 mb-4" key={item.nome}>
                <div className="row d-flex">
                  <div className="col-md-5">
                    <img
                      src={item.urlImagem}
                      alt={item.nome}
                      className="img-fluid"
                    />
                  </div>
                  <div className="col-md-7 flex-column align-self-top">
                    <h4 className="font-weight-bold">{item.nome}</h4>
                    {item.materialTamanhos.map((value) => {
                      return (
                        <div key={value.nome}>
                          <input
                            onChange={() => handleChange(value)}
                            className="checkbox-tamanho"
                            type="checkbox"
                            checked={
                              (materialSelecionado || []).findIndex(
                                (el) =>
                                  el.materialComunicacaoId ===
                                    value.materialComunicacaoId &&
                                  el.largura === value.largura &&
                                  el.altura === value.altura
                              ) !== -1
                            }
                            id={value.nome}
                          />
                          <label className="m-2" htmlFor={value.nome}>
                            {value.nome}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <div className="row mt-5">
        <div className="col-sm-6 text-left">
          <button
            className="btn btn-primary btn-navigation"
            onClick={() => {
              setActiveTab(tabs.tabLayout);
            }}
          >
            <span>
              <FaAngleLeft size={26} />
            </span>
            <span>VOLTAR</span>
          </button>
        </div>

        <div className="col-sm-6 d-flex flex-row-reverse">
          <button
            className={`btn btn-primary btn-navigation btn-navigation-right ${
              materialSelecionado.length ? '' : 'btn-disabled'
            }`}
            onClick={() => {
              if (materialSelecionado.length) {
                submitSolicitacoes();
                setActiveTab(tabs.tabDadosAcao);
              }
            }}
          >
            <span>AVANÇAR</span>
            <span>
              <FaAngleRight size={26} />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Tamanho;
