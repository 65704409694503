/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-use-before-define */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable prefer-const */
import React, { useEffect, useState } from 'react';
import Select, { createFilter } from 'react-select';
import { optimizeSelect } from 'components/_base/OptimizeSelect';

import InputCEP from 'components/_base/InputCEP';
import InputTelefone from 'components/_base/InputTelefone';

const Outro = (props) => {
  let solicitacao = props.solicitacao;
  let entities = props.entities;

  let estados = entities.estados || [];

  solicitacao.entregaOutro = solicitacao.entregaOutro || {};

  let selectListEstados = estados.map((estado) => {
    return {
      value: estado.uf,
      label: estado.nome,
    };
  });
  let initialSelectListCidades = (
    estados.find((x) => x.uf === solicitacao.entregaOutro.uf)?.cidades || []
  ).map((cidade) => {
    return {
      value: cidade.cidadeId,
      label: cidade.nome,
    };
  });

  const [selectListCidades, setSelectListCidades] = useState(
    initialSelectListCidades || []
  );
  const updateSelectListCidades = () => {
    solicitacao.entregaOutro.cidadeId = null;
    setSelectedCidade(null);

    let estado = estados.find((x) => x.uf === solicitacao.entregaOutro.uf);

    setSelectListCidades(
      estado.cidades.map((cidade) => {
        return {
          value: cidade.cidadeId,
          label: cidade.nome,
        };
      })
    );
  };

  const [selectedCidade, setSelectedCidade] = useState(
    (initialSelectListCidades || []).find(
      (item) => item.value === solicitacao.entregaOutro.cidadeId
    ) || null
  );
  const updateSelectedCidade = () => {
    setSelectedCidade(
      (selectListCidades || []).find(
        (item) => item.value === solicitacao.entregaOutro.cidadeId
      ) || null
    );
  };

  // atualizar quando recuperar o rascunho
  useEffect(() => {
    setSelectListCidades(initialSelectListCidades || []);
    setSelectedCidade(
      (initialSelectListCidades || []).find(
        (item) => item.value === solicitacao.entregaOutro.cidadeId
      ) || null
    );
  }, [solicitacao, estados]);

  const [entregaOutro, setEntregaOutro] = useState(solicitacao.entregaOutro);
  const updateEntregaOutro = () => {
    solicitacao.entregaOutro = { ...solicitacao.entregaOutro };
    setEntregaOutro(solicitacao.entregaOutro);
  };

  return (
    <div className="entrega-fisica-panel">
      <div className="row">
        <div className="col-lg-4">
          <div>
            <label>CEP</label>
            <InputCEP
              value={entregaOutro.cep || ''}
              onChange={(value) => {
                solicitacao.entregaOutro.cep = value;
                updateEntregaOutro();
              }}
            />
          </div>

          <div>
            <label>Endereço</label>
            <input
              type="text"
              className="form-control"
              value={entregaOutro.logradouro || ''}
              onChange={(event) => {
                solicitacao.entregaOutro.logradouro = event.target.value;
                updateEntregaOutro();
              }}
            />
          </div>

          <div>
            <label>Número</label>
            <input
              type="text"
              className="form-control"
              value={entregaOutro.numero || ''}
              onChange={(event) => {
                solicitacao.entregaOutro.numero = event.target.value;
                updateEntregaOutro();
              }}
            />
          </div>

          <div>
            <label>Complemento</label>
            <input
              type="text"
              className="form-control"
              value={entregaOutro.complemento || ''}
              onChange={(event) => {
                solicitacao.entregaOutro.complemento = event.target.value;
                updateEntregaOutro();
              }}
            />
          </div>

          <div>
            <label>Bairro</label>
            <input
              type="text"
              className="form-control"
              value={entregaOutro.bairro || ''}
              onChange={(event) => {
                solicitacao.entregaOutro.bairro = event.target.value;
                updateEntregaOutro();
              }}
            />
          </div>
        </div>

        <div className="col-lg-8">
          <div className="row">
            <div className="col-lg-6">
              <div>
                <label>Estado</label>
                <Select
                  options={selectListEstados}
                  isClearable={true}
                  onChange={(item) => {
                    solicitacao.entregaOutro.uf = item?.value;
                    updateSelectListCidades();
                    updateEntregaOutro();
                  }}
                  value={selectListEstados.find(
                    (item) => item.value === solicitacao.entregaOutro?.uf
                  )}
                />
              </div>

              <div>
                <label>Cidade</label>
                <Select
                  components={optimizeSelect.components}
                  filterOption={createFilter({ ignoreAccents: false })}
                  options={selectListCidades || []}
                  isClearable={true}
                  onChange={(item) => {
                    let cidadeId = item?.value ?? 0;
                    if (solicitacao.entregaOutro.cidadeId !== cidadeId) {
                      solicitacao.entregaOutro.cidadeId = cidadeId;
                      updateSelectedCidade();
                      updateEntregaOutro();
                    }
                  }}
                  value={selectedCidade}
                />
              </div>
            </div>

            <div className="col-lg-6">
              <div>
                <label>Razão Social</label>
                <input
                  type="text"
                  className="form-control"
                  value={entregaOutro.razaoSocial || ''}
                  onChange={(event) => {
                    solicitacao.entregaOutro.razaoSocial = event.target.value;
                    updateEntregaOutro();
                  }}
                />
              </div>

              <div>
                <label>CNPJ/CPF</label>
                <input
                  type="text"
                  className="form-control"
                  value={entregaOutro.cnpj || ''}
                  onChange={(event) => {
                    solicitacao.entregaOutro.cnpj = event.target.value;
                    updateEntregaOutro();
                  }}
                />
              </div>
            </div>
            <div />
          </div>

          <div className="row">
            <div className="col-lg-12">
              <hr />

              <h5>Dados do Responsável</h5>

              <div>
                <label>Nome</label>
                <input
                  type="text"
                  className="form-control"
                  value={entregaOutro.responsavelNome || ''}
                  onChange={(event) => {
                    solicitacao.entregaOutro.responsavelNome =
                      event.target.value;
                    updateEntregaOutro();
                  }}
                />
              </div>
            </div>

            <div className="col-lg-6">
              <div>
                <label>Telefone</label>
                <InputTelefone
                  value={entregaOutro.responsavelTelefone || ''}
                  onChange={(value) => {
                    solicitacao.entregaOutro.responsavelTelefone = value;
                    updateEntregaOutro();
                  }}
                />
              </div>
            </div>

            <div className="col-lg-6">
              <div>
                <label>E-mail</label>
                <input
                  type="text"
                  className="form-control"
                  value={entregaOutro.responsavelEmail || ''}
                  onChange={(event) => {
                    solicitacao.entregaOutro.responsavelEmail =
                      event.target.value;
                    updateEntregaOutro();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Outro;
