/* eslint-disable */
import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input';

import './styles.scss';

const ModalProdutos = (props) => {
  const [produtos, setProdutos] = useState(props.produtos || []);

  const handleClose = () => {
    props.updateProdutos(produtos);
    props.closeModal();
  };

  return (
    <Modal
      show={props.show}
      onHide={handleClose}
      className="modal-solicitacaofluxo-produtos"
    >
      <Modal.Header closeButton className="header">
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {produtos.map((produto, index) => {
          return (
            <div key={index} className="row row-produto">
              <div className="col-md-2">
                <img src={produto.urlImagem} className="img-thumbnail" />
              </div>

              <div className="col-md-6">
                <label>Produto</label>
                {produto.nome}
              </div>

              <div className="col-md-2 preco-atual">
                <label>Produto Atual</label>
                <span className="form-control">{produto.displayPreco}</span>
              </div>

              <div className="col-md-2">
                <label>Novo Preço</label>
                <CurrencyInput
                  className="form-control"
                  decimalSeparator=","
                  thousandSeparator=""
                  value={produto.novoPreco || ''}
                  onChangeEvent={(event) => {
                    produto.novoPreco = event.target.value;
                    setProdutos([...produtos]);
                  }}
                />
              </div>
            </div>
          );
        })}
      </Modal.Body>
      <Modal.Footer className="modal-footer">
        <Button onClick={handleClose} className="btn btn-primary">
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalProdutos;
