import React, { useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';

import Extrato from './Extrato';
import ExtratoParcial from './ExtratoParcial';
// import Produtividade from './Produtividade';
import './styles.scss';

const ContentRelatorios = () => {
  // const produtividade = 'produtividade';
  const extrato = 'extrato';
  const extratoparcial = 'extratoparcial';

  const defaultTab = extrato;

  const [activeTab, setActiveTab] = useState(defaultTab);

  return (
    <div className="content_relatorios">
      <h1 className="relatorio-title">RELATÓRIOS</h1>
      <Tabs
        defaultActiveKey={defaultTab}
        onSelect={(tab) => {
          setActiveTab(tab);
        }}
      >
        {/* <Tab eventKey={produtividade} title="Dashboard">
          <div className="text-center iframe-container">
            {activeTab === produtividade && <Produtividade />}
          </div>
        </Tab> */}
        <Tab eventKey={extrato} title="Extrato Mensal">
          {activeTab === extrato && <Extrato />}
          {/* <Extrato /> */}
        </Tab>
        <Tab eventKey={extratoparcial} title="Extrato Parcial">
          {activeTab === extratoparcial && <ExtratoParcial />}
          {/* <ExtratoParcial /> */}
        </Tab>
      </Tabs>
    </div>
  );
};

export default ContentRelatorios;
