import api from 'api';

const PATH = '/Demanda';

const ADD_ASSUMIR_DIAGRAMACAO = async (bodyData) => {
  const { data } = await api.post(`${PATH}/add-assumir-diagramacao`, bodyData);
  return data;
};
const GET_LIST_DEMANDA = async (filtros) => {
  const { data } = await api.get(`${PATH}/get-list-paged-demanda-por-filtro`, {
    params: filtros,
  });
  return data;
};

export { ADD_ASSUMIR_DIAGRAMACAO, GET_LIST_DEMANDA };
