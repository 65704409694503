const initialState = {
  categoriasImagens: [],
  marcas: [],
  categoriasProdutos: [],
  mostrarBotaoDownload: false,
  produtosSelecionados: [],
  marcasSelecionadas: [],
  categoriasImagensSelecionadas: [],
};

export default initialState;
