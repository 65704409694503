/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import PageWithoutHeader from 'components/PageWithoutHeader';
import './styles.scss';

const PasswordChanged = () => {
  return (
    <PageWithoutHeader>
      <div className="page-content password-changed">
        <div className="icon-check">
          <FontAwesomeIcon icon={faCheckCircle} />
        </div>
        <label>Senha alterada com sucesso!</label>
        <p>
          Agora você pode utilizar suas novas informações de segurança para
          acessar sua conta.
        </p>
        <a className="btn btn-link" href="/login">
          <FontAwesomeIcon icon={faArrowLeft} size="1x" /> Voltar para o login
        </a>
      </div>
    </PageWithoutHeader>
  );
};

export default PasswordChanged;
