import api from 'api';

const PATH = '/MaterialComunicacao';

const GET_LIST_MATERIAL_COMUNICACAO_ARTE_FACIL = async () => {
  const { data } = await api.get(
    `${PATH}/get-list-material-comunicacao-arte-facil`
  );
  return data;
};
const GET_SELECT_LIST = async (bodyData) => {
  const { data } = await api.get(`${PATH}/get-select-list`, bodyData);
  return data;
};

export { GET_LIST_MATERIAL_COMUNICACAO_ARTE_FACIL, GET_SELECT_LIST };
