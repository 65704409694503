import api from 'api';

const PATH = '/Aviso';

const GET_LIST_AVISO = async (regionalId) => {
  const { data } = await api.get(`${PATH}/get-list-aviso`, {
    params: {
      regionalId,
    },
  });
  return data;
};

// eslint-disable-next-line import/prefer-default-export
export { GET_LIST_AVISO };
