import React from 'react';

import HeaderApp from 'components/Header/HeaderApp';
import Bottom from 'components/Bottom';
import ContentAlterarSolicitacao from './ContentAlterarSolicitacao';

const AlterarSolicitacao = () => {
  return (
    <>
      <HeaderApp />
      <ContentAlterarSolicitacao />
      <Bottom />
    </>
  );
};

export default AlterarSolicitacao;
