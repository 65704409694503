import React from 'react';
import VMasker from 'vanilla-masker';

const InputCNPJ = ({ className, onChange, value, id }) => {
  return (
    <input
      type="text"
      id={id}
      className={`form-control ${className}`}
      value={VMasker.toPattern(value, '99.999.999/9999-99')}
      onChange={(event) => {
        let input = event.target.value;
        input = VMasker.toPattern(input, '99999999999999');
        onChange(input);
      }}
    />
  );
};

export default InputCNPJ;
