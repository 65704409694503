import React from 'react';

const TextArea = ({
  name,
  type = 'text',
  placeholder = '',
  label,
  disabled = false,
  minLength = 0,
  max = 0,
  rows = 2,
  onChange,
  value,
  className,
}) => {
  return (
    <div className="mb-3">
      <label htmlFor={name} className="form-label">
        {label}
      </label>
      <textarea
        className={`form-control ${className}`}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        id={name}
        onChange={(e) => {
          if (e.target.value.length <= max) {
            onChange(e);
          }
        }}
        value={value}
        rows={rows}
        minLength={minLength}
      />
      <div className="d-flex d-flex justify-content-between">
        <span id="textcount text-danger">
          {value !== undefined && value.length < minLength
            ? `Numero mínimo de caracteres é ${minLength}`
            : null}
          {value !== undefined &&
            value.length === max &&
            value.length > minLength &&
            'Número máximo de caracteres atingidos'}
        </span>
        {/* </div>
      <div className="d-flex justify-content-end"> */}
        <span id="words_count">
          {value !== undefined ? value.length : '0'}/{max}
        </span>
      </div>
    </div>
  );
};

export default TextArea;
