import React from 'react';

import HeaderApp from 'components/Header/HeaderApp';
import Bottom from 'components/Bottom';
import ContentDeliveryMaterials from './ContentDeliveryMaterials';

const DeliveryMaterials = () => {
  return (
    <>
      <HeaderApp />
      <ContentDeliveryMaterials />
      <Bottom />
    </>
  );
};

export default DeliveryMaterials;
