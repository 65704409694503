/* eslint-disable */
import React, { useState } from 'react';
import InputTelefone from 'components/_base/InputTelefone';
import { getCampoObrigatorioClassName } from '../../../_constants';

const CentroDistribuicao = (props) => {
  let model = props.model;

  model.entregaCentroDistribuicao = model.entregaCentroDistribuicao || {};
  const [entregaCentroDistribuicao, setEntregaCentroDistribuicao] = useState(
    model.entregaCentroDistribuicao
  );

  const updateEntregaCentroDistribuicao = () => {
    model.entregaCentroDistribuicao = { ...model.entregaCentroDistribuicao };
    setEntregaCentroDistribuicao(model.entregaCentroDistribuicao);
  };

  return (
    <div className="entrega-fisica-panel">
      <div className="row">
        <div className="col-lg-4">
          <div>
            <label>CEP</label>
            <input
              type="text"
              className="form-control"
              value={model.loja.enderecoFilial?.cep || ''}
              readOnly
            />
          </div>

          <div>
            <label>Endereço</label>
            <input
              type="text"
              className="form-control"
              value={model.loja.enderecoFilial?.endereco || ''}
              readOnly
            />
          </div>

          <div>
            <label>Número</label>
            <input
              type="text"
              className="form-control"
              value={model.loja.enderecoFilial?.numero || ''}
              readOnly
            />
          </div>

          <div>
            <label>Complemento</label>
            <input
              type="text"
              className="form-control"
              value={model.loja.enderecoFilial?.complemento || ''}
              readOnly
            />
          </div>

          <div>
            <label>Bairro</label>
            <input
              type="text"
              className="form-control"
              value={model.loja.enderecoFilial?.bairro || ''}
              readOnly
            />
          </div>
        </div>

        <div className="col-lg-8">
          <div className="row">
            <div className="col-lg-6">
              <div>
                <label>Estado</label>
                <input
                  type="text"
                  className="form-control"
                  value={model.loja.enderecoFilial?.cidade.uf || ''}
                  readOnly
                />
              </div>

              <div>
                <label>Cidade</label>
                <input
                  type="text"
                  className="form-control"
                  value={model.loja.enderecoFilial?.cidade.nome || ''}
                  readOnly
                />
              </div>
            </div>

            <div className="col-lg-6">
              <div>
                <label>CNPJ</label>
                <input
                  type="text"
                  className="form-control"
                  value={model.loja.enderecoFilial?.cnpJ_CPF || ''}
                  readOnly
                />
              </div>

              <div>
                <label>IE</label>
                <input
                  type="text"
                  className="form-control"
                  value={model.loja.enderecoFilial?.iE_RG || ''}
                  readOnly
                />
              </div>
            </div>
            <div />
          </div>

          <div className="row">
            <div className="col-lg-12">
              <hr />

              <h5>Dados do Responsável</h5>

              <div>
                <label>Nome</label>
                <input
                  type="text"
                  className={`form-control ${getCampoObrigatorioClassName(
                    entregaCentroDistribuicao.responsavelNome,
                    true
                  )}`}
                  value={entregaCentroDistribuicao.responsavelNome || ''}
                  onChange={(event) => {
                    model.entregaCentroDistribuicao.responsavelNome =
                      event.target.value;
                    updateEntregaCentroDistribuicao();
                  }}
                />
              </div>
            </div>

            <div className="col-lg-6">
              <div>
                <label>Telefone</label>
                <InputTelefone
                  className={`form-control ${getCampoObrigatorioClassName(
                    entregaCentroDistribuicao.responsavelTelefone,
                    true
                  )}`}
                  value={entregaCentroDistribuicao.responsavelTelefone || ''}
                  onChange={(value) => {
                    model.entregaCentroDistribuicao.responsavelTelefone = value;
                    updateEntregaCentroDistribuicao();
                  }}
                />
              </div>
            </div>

            <div className="col-lg-6">
              <div>
                <label>E-mail</label>
                <input
                  type="text"
                  className={`form-control ${getCampoObrigatorioClassName(
                    entregaCentroDistribuicao.responsavelEmail,
                    true
                  )}`}
                  value={entregaCentroDistribuicao.responsavelEmail || ''}
                  onChange={(event) => {
                    model.entregaCentroDistribuicao.responsavelEmail =
                      event.target.value;
                    updateEntregaCentroDistribuicao();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CentroDistribuicao;
