import React, { useState } from 'react';
import Scroll from 'react-scrollbar';

import './styles.scss';

const Card = (props) => {
  const [materials, setMaterials] = useState([]);
  const { items, title } = props;

  const addRemoveItem = (event) => {
    if (event.target.checked) {
      const item = items.find(
        // eslint-disable-next-line radix
        (obj) => obj.materialComunicacaoId === parseInt(event.target.value)
      );
      setMaterials([...materials, item]);
    } else {
      // eslint-disable-next-line prefer-const
      let newListMaterials = materials;

      const indexItem = materials.findIndex(
        // eslint-disable-next-line radix
        (obj) => obj.materialComunicacaoId === parseInt(event.target.value)
      );
      newListMaterials.splice(indexItem);

      setMaterials([...newListMaterials]);
    }
  };

  return (
    <div className="mm-card">
      <div className="header-card">{title}</div>
      <Scroll speed={0.5} className="body-card">
        <ul>
          {items.map((item, index) => (
            <>
              <div>
                <input
                  onChange={addRemoveItem}
                  type="checkbox"
                  name=""
                  value={item.materialComunicacaoId}
                  id={`check-${item.materialComunicacaoTipo.nome}-${index}`}
                />
                <label
                  htmlFor={`check-${item.materialComunicacaoTipo.nome}-${index}`}
                >
                  <li key={`${item.materialComunicacaoTipo.nome}-${item.nome}`}>
                    {item.nome}
                  </li>
                </label>
              </div>
            </>
          ))}
        </ul>
      </Scroll>
    </div>
  );
};

export default Card;
