import { GET_SELECT_LIST_FILHAS } from 'services/Regional';

const regional = () => {
  async function getRegionalSelectListFilhas() {
    const result = await GET_SELECT_LIST_FILHAS();
    return result.value || [];
  }

  return {
    getRegionalSelectListFilhas,
  };
};

export default regional;
