/* eslint-disable */
import React, { useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';

import './styles.scss';
import api from 'api';
import { Context } from 'context/AuthContext';
import TitlePages from 'components/_base/TitlePages';
import ModalSummary from 'components/ModalSummary';
import Historico from '../../Status/ContentSolicitacaoFluxoStatus/Historico';
import {
  GET_ORCAMENTO_REPROVADO_MODEL,
  RECEBER_ARTE_DIGITAL,
  GET_ORCAMENTO_REPROVADO_CANCELAR,
} from 'services/SolicitacaoFluxo';

const ContentSolicitacaoFluxoOrcamentoReprovado = (props) => {
  const { showLoader } = useContext(Context);

  const paramsLocation = new URLSearchParams(useLocation().search);

  const [solicitacaoId] = useState(
    Number.parseInt(paramsLocation.get('solicitacaoId')) || 0
  );
  const [model, setModel] = useState({});
  const [showComment, setShowComment] = useState(false);
  const [resumoSolicitacaoId, setResumoSolicitacaoId] = useState(0);
  const [resultModel, setResultModel] = useState({});

  useEffect(() => {
    (async () => {
      showLoader(true);

      const responseModel = await GET_ORCAMENTO_REPROVADO_MODEL(solicitacaoId);

      setModel(responseModel.value);

      showLoader(false);
    })();
  }, []);

  function renderResumoButton() {
    if (!model.solicitacaoId || model.solicitacaoId === 0) return null;

    return (
      <div className="text-left">
        <button
          className="btn btn-primary"
          onClick={() => {
            setResumoSolicitacaoId(solicitacaoId);
          }}
        >
          Ver Solicitação
        </button>
      </div>
    );
  }

  function renderHistory() {
    if (!model.solicitacaoId || model.solicitacaoId === 0) return null;

    return (
      <>
        <hr />
        <Historico solicitacaoId={model.solicitacaoId} />
      </>
    );
  }

  function renderButtons() {
    if (!model.solicitacaoId || model.solicitacaoId === 0) return null;

    return (
      <>
        <h4>Deseja receber a arte digital?</h4>
        <button className="m-2 btn btn-success" onClick={aprovar}>
          <FontAwesomeIcon icon={faThumbsUp} /> Sim
        </button>
        <button className="m-2 btn btn-danger" onClick={reprovar}>
          <FontAwesomeIcon icon={faThumbsDown} /> Não
        </button>
      </>
    );
  }

  function aprovar() {
    showLoader(true);

    (async () => {
      const response = await RECEBER_ARTE_DIGITAL(model);

      //value == true => alterado com sucesso
      if (response.value) {
        setModel({});
      }

      setResultModel(response);

      showLoader(false);
    })();
  }

  // function reprovar() {
  //   setShowComment(true);
  // }

  function reprovar() {
    showLoader(true);

    (async () => {
      const response = await GET_ORCAMENTO_REPROVADO_CANCELAR(model);

      //value == true => alterado com sucesso
      if (response.value) {
        setModel({});
      }

      setResultModel(response);

      showLoader(false);
    })();
  }

  function renderComment() {
    if (!model.solicitacaoId || model.solicitacaoId === 0) return null;

    if (!showComment) return null;

    return (
      <>
        <hr />
        <div className="row">
          <div className="col-lg-9">
            <textarea
              rows="7"
              className="form-control"
              value={model.observacao}
              onChange={(e) =>
                setModel({ ...model, observacao: e.target.value })
              }
            ></textarea>
          </div>
        </div>
      </>
    );
  }

  function renderModalSummary() {
    if (resumoSolicitacaoId === 0) return null;

    return (
      <ModalSummary
        solicitacaoId={resumoSolicitacaoId}
        onClose={() => {
          setResumoSolicitacaoId(0);
        }}
      />
    );
  }

  function renderErrors() {
    if (!resultModel.errors || !resultModel.errors.length) return null;

    return (
      <div className="alert alert-danger">
        {resultModel.errors.map((error, index) => {
          return <span key={index}>{error}</span>;
        })}
      </div>
    );
  }

  function renderSuccess() {
    if (!resultModel.message || !resultModel.message.length) return null;

    return (
      <div className="alert alert-success">
        {resultModel.message} <a href="/pendencias">Ir para Pendencias</a>
      </div>
    );
  }

  return (
    <div className="wrapper-content-solicitacaofluxo-aprovar-arte">
      <TitlePages
        title={`Solicitação  ${solicitacaoId} - Orçamento reprovado para impressão`}
      />

      <div className="content-solicitacaofluxo text-center">
        {/* {<pre>{JSON.stringify(resultModel, null, 2)}</pre>} */}

        {renderErrors()}

        {renderSuccess()}

        {renderResumoButton()}

        {renderButtons()}

        {renderComment()}

        {renderHistory()}

        {renderModalSummary()}

        {/* {<pre className="text-left">{JSON.stringify(model, null, 2)}</pre>} */}
      </div>
    </div>
  );
};

export default ContentSolicitacaoFluxoOrcamentoReprovado;
