import api from 'api';

const PATH = '/Rascunho';

const GET_RASCUNHO = async (rascunhoId) => {
  const { data } = await api.get(`${PATH}/get-rascunho/${rascunhoId}`);
  return data;
};
const COUNT_RASCUNHO_USUARIO_ATUAL = async () => {
  const { data } = await api.get(`${PATH}/count-rascunho-usuario-logado`);
  return data;
};
const GET_PEDIDO_RASCUNHO = async (rascunhoId) => {
  const { data } = await api.get(
    `${PATH}/get-pedido-deste-rascunho/${rascunhoId}`
  );
  return data;
};
const GET_COUNT_RASCUNHO = async (filtros) => {
  const { data } = await api.get(`${PATH}/count-rascunho-usuario-logado`, {
    params: filtros,
  });
  return data;
};
const GET_LIST_RASCUNHO = async () => {
  const { data } = await api.get(`${PATH}/get-list-rascunho`);
  return data;
};
const GET_LIST_PAGED_RASCUNHO = async (filtros) => {
  const { data } = await api.get(`${PATH}/get-list-paged-rascunho`, {
    params: filtros,
  });
  return data;
};
const UPDATE_RASCUNHO = async (bodyData) => {
  // Rascunho/web-update-rascunho	Rascunho/update-rascunho-web
  const { data } = await api.post(`${PATH}/update-rascunho-web`, bodyData);
  return data;
};
const DELETE_RASCUNHO = async (rascunhoId) => {
  const { data } = await api.get(`${PATH}/delete-rascunho/${rascunhoId}`);
  return data;
};
const ADD_RASCUNHO = async () => {
  const { data } = await api.post(`${PATH}/add-rascunho`);
  return data;
};

export {
  COUNT_RASCUNHO_USUARIO_ATUAL,
  GET_RASCUNHO,
  GET_PEDIDO_RASCUNHO,
  GET_LIST_PAGED_RASCUNHO,
  GET_LIST_RASCUNHO,
  GET_COUNT_RASCUNHO,
  UPDATE_RASCUNHO,
  DELETE_RASCUNHO,
  ADD_RASCUNHO,
};
