import types from './type-actions';

const actions = {
  setCategoriasImagens: (payload) => {
    return {
      type: types.SET_CATEGORIAS_IMAGENS,
      payload,
    };
  },
  setMarcas: (payload) => {
    return {
      type: types.SET_MARCAS,
      payload,
    };
  },
  setCategoriasProdutos: (payload) => {
    return {
      type: types.SET_CATEGORIAS_PRODUTOS,
      payload,
    };
  },
  mostrarBotaoDownload: (payload) => {
    return {
      type: types.SET_MOSTRAR_BOTAO_DOWNLOAD,
      payload,
    };
  },
  setProdutosSelecionados: (payload) => {
    return {
      type: types.SET_PRODUTOS_SELECIONADOS,
      payload,
    };
  },
  setMarcasSelecionados: (payload) => {
    return {
      type: types.SET_MARCAS_SELCIONADAS,
      payload,
    };
  },
  setCategoriasImagensSelecioandas: (payload) => {
    return {
      type: types.SET_CATEGORIAS_IMAGENS_SELECIOANDAS,
      payload,
    };
  },
};

export default actions;
