import api from 'api';

const PATH = '/Produto';

const GET_SELECT_LIST = async (ids) => {
  const { data } = await api.get(`${PATH}/get-select-list`, {
    params: ids,
  });
  return data;
};
const GET_PRODUTO_ID = async (id) => {
  const { data } = await api.get(`${PATH}/get/${id}`);
  return data;
};
const GET_PRODUTO_LIST = async (filtros) => {
  const { data } = await api.get(`${PATH}/get-list`, {
    params: filtros,
  });
  return data;
};
const GET_PRODUTO_LIST_PAGED = async (filtros) => {
  const { data } = await api.get(`${PATH}/get-list-paged`, {
    params: filtros,
  });
  return data;
};
const ADD_PRODUTO = async (dataBody) => {
  const { data } = await api.post(`${PATH}/add`, dataBody);
  return data;
};
const UPDATE_PRODUTO = async (dataBody) => {
  const { data } = await api.post(`${PATH}/update`, dataBody);
  return data;
};
const REMOVE_PRODUTO = async (id) => {
  const { data } = await api.post(`${PATH}/remove/${id}`);
  return data;
};
const ACTIVATE_PRODUTO = async (id) => {
  const { data } = await api.post(`${PATH}/activate/${id}`);
  return data;
};
const INACTIVATE_PRODUTO = async (id) => {
  const { data } = await api.post(`${PATH}/inactivate/${id}`);
  return data;
};

export {
  GET_SELECT_LIST,
  GET_PRODUTO_ID,
  GET_PRODUTO_LIST,
  GET_PRODUTO_LIST_PAGED,
  ADD_PRODUTO,
  UPDATE_PRODUTO,
  REMOVE_PRODUTO,
  ACTIVATE_PRODUTO,
  INACTIVATE_PRODUTO,
};
