import React from 'react';

import PageTitle from 'components/_base/PageTitle';
import HeaderLogin from 'components/Header/HeaderLogoOnly';
import Bottom from 'components/Bottom';

import './styles.scss';

const PageWithoutHeader = ({ title, children }) => {
  return (
    <div className="page-without-header">
      <HeaderLogin />
      <PageTitle title={title} />
      <hr />
      <div className="wrapper-page">{children}</div>
      <Bottom />
    </div>
  );
};

export default PageWithoutHeader;
