/* eslint-disable */
import React, { useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import history from 'routes/history';

import './styles.scss';
import api from 'api';
import { Context } from 'context/AuthContext';
import TitlePages from 'components/_base/TitlePages';
import ModalSummary from 'components/ModalSummary';
import ArquivoCategoria from './ArquivoCategoria';
import SolicitacaoArquivos from '../../Status/ContentSolicitacaoFluxoStatus/SolicitacaoArquivos';
import Historico from '../../Status/ContentSolicitacaoFluxoStatus/Historico';

import { GET_ENVIAR_ARTE_MODEL, UPLOAD_FILES } from 'services/SolicitacaoFluxo';

const ContentSolicitacaoFluxoEnviarArte = () => {
  const { showLoader } = useContext(Context);

  const paramsLocation = new URLSearchParams(useLocation().search);

  const [solicitacaoId] = useState(
    Number.parseInt(paramsLocation.get('solicitacaoId')) || 0
  );
  const [model, setModel] = useState({});
  const [resumoSolicitacaoId, setResumoSolicitacaoId] = useState(0);
  const [resultModel, setResultModel] = useState({});

  useEffect(() => {
    (async () => {
      showLoader(true);

      const response = await GET_ENVIAR_ARTE_MODEL(solicitacaoId);

      if (!response.value) {
        history.push(
          '/solicitacao-fluxo/status?solicitacaoId=' + solicitacaoId
        );
      } else {
        setModel(response.value);
      }

      showLoader(false);
    })();
  }, []);

  function renderResumoButton() {
    if (!model.solicitacaoId || model.solicitacaoId === 0) return null;

    return (
      <div className="text-left">
        <button
          className="btn btn-primary"
          onClick={() => {
            setResumoSolicitacaoId(solicitacaoId);
          }}
        >
          Ver Solicitação
        </button>
      </div>
    );
  }

  function renderModalSummary() {
    if (resumoSolicitacaoId === 0) return null;

    return (
      <ModalSummary
        solicitacaoId={resumoSolicitacaoId}
        onClose={() => {
          setResumoSolicitacaoId(0);
        }}
      />
    );
  }

  function renderHistory() {
    if (!model.solicitacaoId || model.solicitacaoId === 0) return null;

    return (
      <>
        <hr />
        <Historico solicitacaoId={model.solicitacaoId} />
      </>
    );
  }

  function renderArquivoCategorias() {
    if (!model.arquivoCategorias || model.arquivoCategorias.length === 0)
      return null;

    return (
      <>
        <hr />
        <div className="row">
          {model.arquivoCategorias.map((arquivoCategoria, index) => {
            return (
              <ArquivoCategoria
                key={index}
                arquivoCategoria={arquivoCategoria}
              />
            );
          })}
        </div>
      </>
    );
  }

  function enviar() {
    showLoader(true);

    let postModel = { ...model };

    (async () => {
      const response = await UPLOAD_FILES(postModel);

      //value == true => sucesso
      if (response.value) {
        setModel({});

        history.push(
          '/solicitacao-fluxo/confirmar-envio?solicitacaoId=' + solicitacaoId
        );
      }

      setResultModel(response);

      showLoader(false);
    })();
  }

  function renderErrors() {
    if (!resultModel.errors || !resultModel.errors.length === 0) return null;

    return (
      <div className="alert alert-danger">
        {resultModel.errors.map((error, index) => {
          return <span key={index}>{error}</span>;
        })}
      </div>
    );
  }

  function renderSuccess() {
    if (!resultModel.message || !resultModel.message.length) return null;

    return (
      <div className="alert alert-success">
        {resultModel.message} <a href="/pendencias">Ir para Pendencias</a>
      </div>
    );
  }

  return (
    <div className="wrapper-content-solicitacaofluxo-enviar-arte">
      <TitlePages title={'Solicitação ' + solicitacaoId + ' - Enviar Arte'} />

      <div className="content-solicitacaofluxo">
        {/* {<pre>{JSON.stringify(resultModel, null, 2)}</pre>} */}

        {renderErrors()}

        {renderSuccess()}

        {renderResumoButton()}

        {renderArquivoCategorias()}

        <div className="mt-5">
          <button className="btn btn-primary" onClick={enviar}>
            Enviar
          </button>
        </div>

        <hr />

        <SolicitacaoArquivos solicitacaoId={model.solicitacaoId || 0} />

        {/* {renderSummary()} */}

        {renderHistory()}

        {renderModalSummary()}

        {/* {renderModalComment()} */}

        {/* {<pre className="text-left">{JSON.stringify(model, null, 2)}</pre>} */}
      </div>
    </div>
  );
};

export default ContentSolicitacaoFluxoEnviarArte;
