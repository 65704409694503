/* eslint-disable */
import React, { useState, useContext, useEffect, useLayoutEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Button,
  Row,
  Col,
  Alert,
  Tabs,
  Tab,
  Sonnet,
  FormGroup,
} from 'react-bootstrap';
import {
  FaHome,
  FaAngleRight,
  FaSearch,
  FaPeopleCarry,
  FaUserCheck,
  FaFileUpload,
  FaChevronRight,
  FaChevronLeft,
  FaPlusCircle,
  FaCalendarCheck,
  FaFileDownload,
  FaTelegramPlane,
  FaImages,
} from 'react-icons/fa';

import './styles.scss';
import history from 'routes/history';
import api from 'api';
import TitlePages from 'components/_base/TitlePages';
import LoaderAction from '../../../../store/modules/loader/actions';
//import InputFileCustom from "../../../atoms/InputFile";
import {
  faCaretRight,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Context } from 'context/AuthContext';
import SingleSelect from 'components/_base/SelectSingle';
import FiltroExtrato from '../Filtro';
import ExtratoDownloads from '../Downloads';
import {GET_SELECT_LIST_REGIONAL} from '../../../../services/Regional';

const Extrato = (props) => {
  const { showLoader } = useContext(Context);

  const [filtro, setFiltro] = useState({});
  const [regionais, setRegionais] = useState([]);
  var regs = [{ label: '', options: [{ label: 'BRASIL', value: 1000 }] }];
  useEffect(() => {
    (async () => {
      showLoader(true);

      let resultRegionais = await GET_SELECT_LIST_REGIONAL();
      if (
        resultRegionais?.value != null &&
        resultRegionais?.value != [] &&
        regs.length == 1
      ) {
        regs[0].options = regs[0].options.concat(
          resultRegionais?.value[0]?.options
        );
      }
      setRegionais(regs || []);

      showLoader(false);
    })();
  }, []);

  function onSelect(filtro) {
    setFiltro(filtro);
  }

  return (
    <div className="brf-extrato">
      <FiltroExtrato onSelect={onSelect} regionais={regionais} />
      <ExtratoDownloads regional={regionais} filtro={filtro} />
    </div>
  );
};

export default Extrato;
