/* eslint-disable */
import React, { useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import history from 'routes/history';
import { Context } from 'context/AuthContext';
import api from 'api';
import { GET_LIST_PAGED_RASCUNHO } from 'services/Rascunho';

import TableList from './TableList';

import './styles.scss';

const ContentQuery = () => {
  const paramsLocation = new URLSearchParams(useLocation().search);

  const initialState = {
    pageNumber: paramsLocation.get('pageNumber') || 1,
    pageSize: paramsLocation.get('pageSize') || 25,
    list: [],
    pageData: {},
  };

  const { showLoader } = useContext(Context);
  const [model, setModel] = useState(initialState);
  const [refresh, setRefresh] = useState([]);

  useEffect(() => {
    history.listen((location) => {
      if (history.action === 'POP') {
        window.location = location.pathname + location.search;
      }
    });

    (async () => {
      showLoader(true);
      let params = getFilter();
      const data = await GET_LIST_PAGED_RASCUNHO(params);
      setModel({
        ...model,
        pageData: data.value,
        list: data.value.data,
      });
      showLoader(false);
    })();
  }, [refresh]);

  const getFilter = () => {
    return {
      pageNumber: model.pageNumber,
      pageSize: model.pageSize,
    };
  };

  const filterList = () => {
    let query = new URLSearchParams(getFilter()).toString();
    history.push({ pathname: '/rascunho', search: '?' + query });
    setRefresh(!refresh);
  };

  const handlePageChange = (pageNumber) => {
    model.pageNumber = pageNumber;
    filterList();
  };

  return (
    <>
      <TableList
        model={model}
        onRefresh={filterList}
        handlePageChange={handlePageChange}
      ></TableList>
    </>
  );
};

export default ContentQuery;
