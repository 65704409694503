/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { GET_LIST_PERGUNTAS } from 'services/PerguntaFrequente';
import { UncontrolledCollapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

import './styles.scss';

const Doubts = () => {
  const [duvidas, setDuvidas] = useState([]);

  useEffect(() => {
    (async () => {
      const data = await GET_LIST_PERGUNTAS();
      setDuvidas(data.value);
    })();
  }, []);

  return (
    <div className="duvidasFrequentes">
      <div className="title">Dúvidas Frequentes</div>
      <div className="info">
        {duvidas.map((duvida) => (
          <div className="rowDoubts" key={duvida.perguntaFrequenteId}>
            <label
              className=" col-md-8"
              id={`duvida${duvida.perguntaFrequenteId}`}
            >
              {duvida.perguntaFrequenteId} . {duvida.pergunta}
              <FontAwesomeIcon className="icone" icon={faAngleDown} size="lg" />
            </label>
            <UncontrolledCollapse
              id="respostas"
              toggler={`#duvida${duvida.perguntaFrequenteId}`}
            >
              <span className="col-md-8 ">
                {duvida.resposta} <hr />
              </span>
            </UncontrolledCollapse>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Doubts;
