/* eslint-disable no-plusplus */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-array-index-key */
/* eslint-disable prefer-const */
/* eslint-disable no-use-before-define */
/* eslint-disable no-else-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

class TablePagination extends Component {
  renderTotal() {
    if (!this.props.pageData) return null;

    if (this.props.pageData.totalRows === 0) {
      return (
        <div className="row">
          <div className="col-md-12">
            <label className="label-pagination">
              Nenhum registro encontrado.
            </label>
          </div>
        </div>
      );
    } else if (this.props.pageData.totalRows > 0) {
      return (
        <div className="row">
          <div className="col-md-12">
            <label className="label-pagination">
              Total de registros: {this.props.pageData.totalRows}
            </label>
          </div>
        </div>
      );
    }

    return null;
  }

  renderPagination() {
    let pages = getPages(this.props.pageData);

    if (!pages || pages.length < 2) return null;

    return (
      <Pagination
        style={{
          display: 'inline-flex',
        }}
      >
        <PaginationItem
          disabled={!this.props.pageData.hasPrevious}
          onClick={(e) => {
            if (this.props.pageData.hasPrevious) {
              this.handlePageChange(e, this.props.pageData.pageNumber - 1);
            }
          }}
        >
          <PaginationLink previous href="#" />
        </PaginationItem>

        {pages.map((page, index) => (
          <PaginationItem
            disabled={this.props.pageData.disabled}
            key={index}
            active={page === this.props.pageData.pageNumber}
            onClick={(e) => this.handlePageChange(e, page)}
          >
            <PaginationLink href="#">{page}</PaginationLink>
          </PaginationItem>
        ))}

        <PaginationItem
          disabled={!this.props.pageData.hasNext}
          onClick={(e) => {
            if (this.props.pageData.hasNext) {
              this.handlePageChange(e, this.props.pageData.pageNumber + 1);
            }
          }}
        >
          <PaginationLink next href="#" />
        </PaginationItem>
      </Pagination>
    );
  }

  render() {
    if (!this.props.pageData) return null;

    return (
      <div>
        {this.renderPagination()}
        {this.renderTotal()}
      </div>
    );
  }

  handlePageChange = (e, pageNumber) => {
    e.preventDefault();

    this.props.handlePageChange(pageNumber);
  };
}

function getPages(pageData) {
  let pages = [];

  if (!pageData) return pages;

  let pageCount = pageData.totalPages;
  let pageNum = pageData.pageNumber;
  let maximoPaginas = 5;
  let inicio =
    pageCount - pageNum + 1 >= maximoPaginas
      ? pageNum
      : pageCount - maximoPaginas + 1;

  if (inicio <= 0) inicio = 1;

  for (let i = inicio; i <= pageCount && pages.length < 5; i++) {
    pages.push(i);
  }

  return pages;
}

export default TablePagination;
