import React from 'react';

import HeaderApp from 'components/Header/HeaderApp';
import Bottom from 'components/Bottom';
import ContentSolicitacaoFluxoAprovarArte from './ContentSolicitacaoFluxoAprovarArte';

const SolicitacaoAprovarArte = () => {
  return (
    <>
      <HeaderApp />
      <ContentSolicitacaoFluxoAprovarArte />
      <Bottom />
    </>
  );
};

export default SolicitacaoAprovarArte;
