import React from 'react';

import Header from 'components/Header/HeaderApp';
import Bottom from 'components/Bottom';

import ContentBancoImagens from './ContentBancoImagens';

const BancoImagens = () => {
  return (
    <>
      <Header />
      <ContentBancoImagens path="banco-de-imagens" />
      <Bottom />
    </>
  );
};

export default BancoImagens;
