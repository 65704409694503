/* eslint-disable */
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import './styles.scss';

const ModalComment = (props) => {
  const [observacao, setObservacao] = useState('');
  const [enviarObservacao] = useState(() => props.enviarObservacao);
  const [cancelarObservacao] = useState(() => props.cancelarObservacao);

  return (
    <div
      className="off-light"
      style={{ display: props.show ? 'flex' : 'none' }}
    >
      <div className="mm-modal modal-lg">
        <div className="mm-header-modal">
          <div className="mm-header-close">
            <FontAwesomeIcon
              icon={faTimes}
              onClick={() => cancelarObservacao()}
            ></FontAwesomeIcon>
          </div>
          <h4>Comentário</h4>
        </div>
        <div className="mm-body-modal">
          <textarea
            className="form-control"
            onChange={(event) => setObservacao(event.target.value)}
            value={observacao}
            rows="6"
          ></textarea>
        </div>
        <div className="mm-footer-modal">
          <div>
            <button onClick={() => enviarObservacao(observacao)}>Enviar</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalComment;
