import React, { useContext, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { Context } from 'context/AuthContext';

const Acesso = () => {
  const { loginWithToken } = useContext(Context);
  const history = useHistory();

  const search = new URLSearchParams(useLocation().search);
  const token = search.get('token') ? search.get('token') : '';

  useEffect(() => {
    if (token.length)
      (async () => {
        await loginWithToken(token);
      })();
    else history.push('/login');
  }, []);

  return <></>;
};

export default Acesso;
