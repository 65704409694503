const types = {
  SET_CATEGORIAS_IMAGENS: 'SET_CATEGORIAS_IMAGENS',
  SET_MARCAS: 'SET_MARCAS',
  SET_CATEGORIAS_PRODUTOS: 'SET_CATEGORIAS_PRODUTOS',
  SET_MOSTRAR_BOTAO_DOWNLOAD: 'SET_MOSTRAR_BOTAO_DOWNLOAD',
  SET_PRODUTOS_SELECIONADOS: 'SET_PRODUTOS_SELECIONADOS',
  SET_MARCAS_SELCIONADAS: 'SET_MARCAS_SELCIONADAS',
  SET_CATEGORIAS_IMAGENS_SELECIOANDAS: 'SET_CATEGORIAS_IMAGENS_SELECIOANDAS',
};

export default types;
