import api from 'api';

const PATH = '/BancoImagem';

const GET_BANCO_IMAGEM = async (id) => {
  const { data } = await api.get(`${PATH}/get-banco-imagem/${id}`);
  return data;
};
const GET_BANCO_IMAGEM_CATEGORIAS = async () => {
  const { data } = await api.get(`${PATH}/get-list-categoria-banco-imagem`);
  return data;
};
const GET_LIST_BANCO_IMAGEM_FILTRO = async (filtros) => {
  const { data } = await api.get(
    `${PATH}/get-list-paged-banco-imagem-por-filtro`,
    filtros
  );
  return data;
};

export {
  GET_BANCO_IMAGEM,
  GET_BANCO_IMAGEM_CATEGORIAS,
  GET_LIST_BANCO_IMAGEM_FILTRO,
};
