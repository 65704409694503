import React from 'react';

import Header from 'components/Header/HeaderApp';
import Bottom from 'components/Bottom';
import ContentBancoArtes from './ContentBancoArtes';

const BancoArtes = () => {
  return (
    <>
      <Header />
      <ContentBancoArtes style={{ minHeight: '100%' }} />
      <Bottom style={{ bottom: '0', position: 'absolute' }} />
    </>
  );
};

export default BancoArtes;
