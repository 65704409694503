import React from 'react';

import Header from 'components/Header/HeaderApp';
import Bottom from 'components/Bottom';
import ContentAtendimento from './ContentAtendimento';

const Atendimento = () => {
  return (
    <>
      <Header />
      <ContentAtendimento />
      <Bottom />
    </>
  );
};

export default Atendimento;
