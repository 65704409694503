import api from 'api';

const PATH = '/Pedido';

const CREATE_RASCUNHO_COPIA_PEDIDO = async (pedidoId) => {
  const { data } = await api.post(
    `${PATH}/create-rascunho-copia-pedido/${pedidoId}`
  );
  return data;
};
const GET_PEDIDO_POR_SOLICITACAO = async (solicitacaoId) => {
  const { data } = await api.get(
    `${PATH}/get-pedido-por-solicitacao/${solicitacaoId}`
  );
  return data;
};
const GET_LIST_PEDIDO = async (filtros) => {
  const { data } = await api.get(`${PATH}/get-list-pedido-por-filtro`, {
    params: filtros,
  });
  return data;
};
const GET_PEDIDO_TIPO_ARTES_FACIL = async () => {
  const { data } = await api.get(`${PATH}/get-pedido-tipo-de-artes-facil`);
  return data;
};
const GET_PEDIDO_TIPO_CAMPANHA = async () => {
  const { data } = await api.get(`${PATH}/get-pedido-tipo-de-campanha`);
  return data;
};
const GET_PEDIDO_TIPO_MATERIAL = async () => {
  const { data } = await api.get(`${PATH}/get-pedido-tipo-de-material`);
  return data;
};
const GET_PEDIDO_TIPO_SOLICITACAO = async (solicitacaoId) => {
  const { data } = await api.get(
    `${PATH}/get-pedido-tipo-por-solicitacao/${solicitacaoId}`
  );
  return data;
};
const ADD_PEDIDO = async (id, dataBody) => {
  // Pedido/web-add-pedido	Pedido/add-pedido-web
  const { data } = await api.post(`${PATH}/add-pedido-web/${id}`, dataBody);
  return data;
};
const VALIDATE_PEDIDO = async (dataBody) => {
  const { data } = await api.post(`${PATH}/validate-web`, dataBody);
  return data;
};
const GET_PEDIDO_ID = async (id) => {
  const { data } = await api.get(`${PATH}/get-pedido/${id}`);
  return data;
};

const UPLOAD_ANEXO = async (dataBody) => {
  const { data } = await api.post(`${PATH}/upload-file`, dataBody, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
      'WWW-Authenticate': `Bearer ${
        localStorage.getItem('midias_camil_site') == null
          ? undefined
          : localStorage.getItem('midias_camil_site').jwtToken
      }`,
    },
  });
  return data;
};

export {
  GET_LIST_PEDIDO,
  GET_PEDIDO_POR_SOLICITACAO,
  GET_PEDIDO_TIPO_ARTES_FACIL,
  GET_PEDIDO_TIPO_CAMPANHA,
  GET_PEDIDO_TIPO_MATERIAL,
  GET_PEDIDO_TIPO_SOLICITACAO,
  GET_PEDIDO_ID,
  ADD_PEDIDO,
  VALIDATE_PEDIDO,
  CREATE_RASCUNHO_COPIA_PEDIDO,
  UPLOAD_ANEXO,
};
