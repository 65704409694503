import React from 'react';

import HeaderApp from 'components/Header/HeaderApp';
import Bottom from 'components/Bottom';
import ContentSelectMaterials from './ContentSelectMaterials';

const SelectMaterials = () => {
  return (
    <>
      <HeaderApp />
      <ContentSelectMaterials />
      <Bottom />
    </>
  );
};

export default SelectMaterials;
