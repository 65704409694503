/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */
import React from 'react';
import { FixedSizeList } from 'react-window';
import { components } from 'react-select';

// eslint-disable-next-line import/prefer-default-export
export const optimizeSelect = {
  components: {
    MenuList: OptimizedMenuList,
    Option: OptimizedOption,
  },
};

function OptimizedMenuList(props) {
  const { options, children, maxHeight, getValue } = props;
  if (!children || !Array.isArray(children)) return null;

  const height = 35;
  const selectedValues = getValue();
  const initialOffset = selectedValues[0]
    ? options.indexOf(selectedValues[0]) * height
    : 0;

  return (
    <FixedSizeList
      width=""
      itemSize={height}
      height={maxHeight}
      itemCount={children.length}
      initialScrollOffset={initialOffset}
    >
      {({ index, style }) => (
        <div className="option-wrapper" style={style}>
          {children[index]}
        </div>
      )}
    </FixedSizeList>
  );
}

function OptimizedOption(props) {
  delete props.innerProps.onMouseMove;
  delete props.innerProps.onMouseOver;
  return <components.Option {...props}>{props.children}</components.Option>;
}
