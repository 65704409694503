import api from 'api';

const PATH = '/BannerLogin';

const GET_LIST_BANNER_LOGIN = async () => {
  const { data } = await api.get(`${PATH}/get-list-banner-login`);
  return data;
};

// eslint-disable-next-line import/prefer-default-export
export { GET_LIST_BANNER_LOGIN };
