import React from 'react';

import Header from 'components/Header/HeaderApp';
import Bottom from 'components/Bottom';
import ContentChamadoFinished from './ContentChamadoFinished';

const ChamadoSent = () => {
  return (
    <>
      <Header />
      <ContentChamadoFinished />
      <Bottom />
    </>
  );
};

export default ChamadoSent;
