import React from 'react';
import './styles.scss';
import TitlePages from 'components/_base/TitlePages';
import FormDataUser from 'components/FormDataUser';
import FormPassword from 'components/FormPassword';

const ContentDadosUsuario = () => {
  return (
    <div className="wrapper-dados-usuario">
      <TitlePages title="MEUS DADOS" />
      <div className="content-meus-dados">
        <div className="dados col-lg-10">
          <FormDataUser />
        </div>
        <div className=" senha col-lg-10">
          <FormPassword />
        </div>
      </div>
    </div>
  );
};

export default ContentDadosUsuario;
