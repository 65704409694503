/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

import { dateFormat } from '../../helpers/Date.Helper';

import './styles.scss';

const Question = (props) => {
  return (
    <div className="content-pergunta">
      <div className="content">
        <div className="pergunta">
          <div className="nome">
            <FontAwesomeIcon icon={faQuestionCircle} />
            <strong>{props.resposta.usuario.nome}</strong>
          </div>
          <div className="texto">
            {props.resposta.urlArquivo != null ? (
              <div>
                <p>{props.resposta.resposta}</p>
                <img src={props.resposta.urlArquivo} alt="" />
              </div>
            ) : (
              <p>{props.resposta.resposta}</p>
            )}
          </div>
          <div className="data">
            <output>{dateFormat(props.resposta.data)}</output>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Question;
