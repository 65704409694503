/* eslint-disable */
import React, { useEffect, useState } from 'react';
import actionLoader from 'store/modules/loader/actions';
import { useDispatch } from 'react-redux';

import api from 'api';
import { GET_HISTORICO_MODEL } from 'services/SolicitacaoFluxo';
import './styles.scss';

const Historico = (props) => {
  let solicitacaoId = props.solicitacaoId;
  const [historico, setHistorico] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      if (solicitacaoId && solicitacaoId > 0) {
        dispatch(actionLoader.showLoader(true));

        const data = await GET_HISTORICO_MODEL(props.solicitacaoId);

        setHistorico(data.value);

        dispatch(actionLoader.showLoader(false));
      } else {
        setHistorico({});
      }
    })();
  }, [solicitacaoId]);

  if (
    !historico.solicitacaoId ||
    !historico.itens ||
    !historico.itens.length === 0
  )
    return null;

  return (
    <div className="content-historico">
      {historico.itens.map((item, index) => {
        return (
          <div key={index} className="historico-item">
            <label>{item.etapa}</label>
            <label className="item-data">
              {item.data} {item.hora} - {item.usuario}
            </label>
            {item.observacao && item.observacao.length > 0 && (
              <pre>{item.observacao}</pre>
            )}

            <hr />
          </div>
        );
      })}
    </div>
  );
};

export default Historico;
