import React from 'react';
import { Link } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';

import HeaderApp from 'components/Header/HeaderApp';
import Bottom from 'components/Bottom';

import imageErro from 'assets/imgs/erro.png';

const PageNotFound = () => {
  return (
    <>
      <HeaderApp />
      <div className="d-flex justify-content-center align-items-center content-acesso-restrito">
        <div className="text-center p-4">
          <img src={imageErro} alt="erro" />
          <h1>Página não encontrada</h1>
          <h5>
            Página não encontrada Ocorreu um erro ao tentar processar a
            requisição. <br />
            Por favor, tente novamente
          </h5>
          <Link to="/">
            <button
              type="button"
              className="mt-3 btn btn-primary btn-navigation"
            >
              <span>
                <FaHome size={26} />
                <span className="ml-2">PÁGINA INICIAL</span>
              </span>
            </button>
          </Link>
        </div>
      </div>
      <Bottom />
    </>
  );
};

export default PageNotFound;
