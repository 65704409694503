import React from 'react';
import VMasker from 'vanilla-masker';

export default ({ className, onChange, value }) => {
  return (
    <input
      type="text"
      className={`form-control ${className}`}
      value={value}
      onChange={(event) => {
        let input = event.target.value;
        input = VMasker.toPattern(input, '99999999999999999999');
        input = VMasker.toPattern(input, '99999-999');
        onChange(input);
      }}
    />
  );
};
