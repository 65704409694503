import api from 'api';

const PATH = '/Download';

const GET_IMAGE_ZIP = async (ids) => {
  const { data } = await api({
    url: `${PATH}/get-image-zip`,
    method: 'GET',
    responseType: 'blob',
    params: {
      ids: ids.join(','),
    },
  });

  return data;
};

// eslint-disable-next-line import/prefer-default-export
export { GET_IMAGE_ZIP };
