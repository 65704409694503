import React from 'react';
import './styles.scss';

export default (props) => {
  const { file, name, onChange } = props;
  return (
    <>
      <div className="input-file-custom">
        <label htmlFor={name}>
          Faça upload do arquivo
          <input
            type="file"
            className="input-file"
            id={name}
            name={name}
            onChange={(e) => onChange(e, name)}
          />
        </label>
        {file && <span id="file-name">{file ? file.name : ''}</span>}
      </div>
    </>
  );
};
