/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import { FaAngleLeft } from 'react-icons/fa';

import InputCEP from 'components/_base/InputCEP';
import SelectSingle from 'components/_base/SelectSingle';
import InputCNPJ from 'components/_base/InputCNPJ';
import {
  GET_SELECT_LIST_REDE,
  GET_SELECT_LIST_REGIONAL,
  GET_SELECT_LIST_CANAL,
  GET_LIST_CIDADE,
  GET_LIST_ESTADO,
  ADD_LOJA,
} from 'services/Solicitacao';
import { Context } from 'context/AuthContext';

const AdicionarCliente = (props) => {
  const { showLoader } = useContext(Context);
  const { setActiveTab, setModel } = props;

  let model = props.model;

  const [listEstado, setListEstado] = useState('');
  const [listCidade, setListCidade] = useState('');
  const [listRede, setListRede] = useState('');
  const [listCanal, setListCanal] = useState('');
  const [listRegional, setListRegional] = useState('');
  const [erro, setErro] = useState([]);
  const [form, setForm] = useState({
    razaoSocial: '',
    nomeFantasia: '',
    cnpj: '',
    ie: '',
    regionalId: 0,
    canalId: 0,
    redeId: 0,
    logradouro: '',
    numero: '',
    complemento: '',
    bairro: '',
    cep: '',
    uf: 0,
    cidadeId: 0,
  });

  useEffect(() => {
    (async () => {
      showLoader(true);
      const { value: selectListRede } = await GET_SELECT_LIST_REDE();
      setListRede(selectListRede);
      const { value: selectListRegional } = await GET_SELECT_LIST_REGIONAL();
      setListRegional(selectListRegional);
      const { value: selectListCanal } = await GET_SELECT_LIST_CANAL();
      setListCanal(selectListCanal);
      const { value: estadosList } = await GET_LIST_ESTADO();
      const selectListEstado = estadosList.map((estadoAtual) => {
        return {
          value: estadoAtual.uf,
          label: estadoAtual.nome,
        };
      });
      setListEstado(selectListEstado);
      showLoader(false);
    })();
  }, []);

  const changeListCidade = async (uf) => {
    const { value: cidadesList } = await GET_LIST_CIDADE(uf);
    const selectListCidade = cidadesList.map((cidadeAtual) => {
      return {
        value: cidadeAtual.cidadeId,
        label: cidadeAtual.nome,
      };
    });
    setListCidade(selectListCidade);
  };

  const validacao = async (formData) => {
    // eslint-disable-next-line prefer-const
    let todosErros = [];
    !formData.razaoSocial &&
      todosErros.push('O campo Razão social é obrigatório.');
    !formData.nomeFantasia &&
      todosErros.push('O campo Nome Fantasia é obrigatório.');
    !formData.cnpj && todosErros.push('O campo CNPJ é obrigatório.');
    !formData.regionalId && todosErros.push('O campo Regional é obrigatório.');
    !formData.canalId && todosErros.push('O campo Canal é obrigatório.');
    // !form.redeId && todosErros.push('Rede');
    // !formData.logradouro &&
    //   todosErros.push('O campo Logradouro é obrigatório.');
    // !formData.numero && todosErros.push('O campo Numero é obrigatório.');
    // !formData.bairro && todosErros.push('O campo Bairro é obrigatório.');
    // !formData.cep && todosErros.push('O campo CEP é obrigatório.');
    !formData.uf && todosErros.push('O campo UF é obrigatório.');
    // !formData.cidadeId && todosErros.push('O campo Cidade é obrigatório.');

    if (!todosErros.length) {
      setErro([]);
      return true;
    }
    setErro(todosErros);
    return false;
  };

  const addLoja = async (formBody) => {
    showLoader(true);
    if (await validacao(formBody)) {
      const data = await ADD_LOJA(formBody);
      if (data.code === 200) {
        setForm({
          razaoSocial: '',
          nomeFantasia: '',
          cnpj: '',
          ie: '',
          regionalId: 0,
          canalId: 0,
          redeId: 0,
          logradouro: '',
          numero: '',
          complemento: '',
          bairro: '',
          cep: '',
          uf: 0,
          cidadeId: '',
        });
        setModel({ loja: data.value, lojaId: data.value.lojaId });
        setActiveTab('cliente');
      } else {
        setErro(data.errors);
      }
    }
    showLoader(false);
  };

  return (
    <div className="wrapper-content-pedido-cliente">
      <div className="content-pedido-cliente">
        <div className="col-md-12">
          <div className="solicitacao-panel">
            <div className="solicitacao-panel-header">Preencha os Dados</div>
            <div className="solicitacao-panel-body form-dados-acao">
              <div className="col-12">
                {erro && erro.length !== 0 && (
                  <div className="alert alert-danger text-left">
                    <div className="row">
                      <div className="offset-2">
                        {erro.map((value) => {
                          return (
                            <p className="m-0" key={value}>
                              {value}
                            </p>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}
                <div className="row mb-2">
                  <div className="col-md-2 col-sm-12 text-md-right text-sm-center pr-0">
                    <label htmlFor="razao-social">Razão Social</label>
                  </div>
                  <div className="col-md-10 col-sm-12">
                    <input
                      type="text"
                      id="razao-social"
                      className={`form-control ${
                        !form.razaoSocial && 'border-red-validate'
                      }`}
                      value={form.razaoSocial}
                      onChange={(e) =>
                        setForm({ ...form, razaoSocial: e.target.value })
                      }
                    />
                  </div>
                </div>

                <div className="row mb-2">
                  <div className="col-md-2 col-sm-12 text-md-right text-sm-center pr-0">
                    <label htmlFor="nome-fantasia">Nome Fantasia</label>
                  </div>
                  <div className="col-md-10 col-sm-12">
                    <input
                      type="text"
                      id="nome-fantasia"
                      className={`form-control ${
                        !form.nomeFantasia && 'border-red-validate'
                      }`}
                      value={form.nomeFantasia}
                      onChange={(e) =>
                        setForm({ ...form, nomeFantasia: e.target.value })
                      }
                    />
                  </div>
                </div>

                <div className="row mb-2">
                  <div className="col-md-6 col-sm-12">
                    <div className="row">
                      <div className="col-md-4 text-md-right text-sm-center pr-0">
                        <label htmlFor="cnpj">CNPJ</label>
                      </div>
                      <div className="col-md-8">
                        <InputCNPJ
                          value={form.cnpj}
                          id="cnpj"
                          className={`form-control ${
                            !form.cnpj && 'border-red-validate'
                          }`}
                          onChange={(value) =>
                            setForm({ ...form, cnpj: value })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="row">
                      <div className="col-md-4 text-md-right text-sm-center pr-0">
                        <label htmlFor="ie">Inscrição Estadual</label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          id="ie"
                          className="form-control"
                          value={form.ie}
                          onChange={(e) =>
                            setForm({
                              ...form,
                              ie: e.target.value?.replace(/\D/, ''),
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mb-2">
                  <div className="col-md-6 col-sm-12">
                    <div className="row">
                      <div className="col-md-4 text-md-right text-sm-center pr-0">
                        <label htmlFor="razao-social">Regional</label>
                      </div>
                      <div className="col-md-8">
                        <SelectSingle
                          value={form.regionalId}
                          name="estado"
                          isClearable
                          obrigatorio={!form.regionalId}
                          onChange={(value) => {
                            setForm({ ...form, regionalId: value });
                          }}
                          options={listRegional || []}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="row">
                      <div className="col-md-4 text-md-right text-sm-center pr-0">
                        <label htmlFor="canal">Canal</label>
                      </div>
                      <div className="col-md-8">
                        <SelectSingle
                          value={form.canalId}
                          name="canal"
                          isClearable
                          obrigatorio={!form.canalId}
                          onChange={(value) => {
                            setForm({ ...form, canalId: value });
                          }}
                          options={listCanal || []}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-md-6 col-sm-12">
                    <div className="row">
                      <div className="col-md-4 text-md-right text-sm-center pr-0">
                        <label htmlFor="rede">Rede</label>
                      </div>
                      <div className="col-md-8">
                        <SelectSingle
                          value={form.redeId}
                          name="rede"
                          isClearable
                          onChange={(value) => {
                            setForm({ ...form, redeId: value });
                          }}
                          options={listRede || []}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="row">
                      <div className="col-md-4 text-md-right text-sm-center pr-0">
                        <label htmlFor="logradouro">Logradouro</label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          id="logradouro"
                          className="form-control"
                          value={form.logradouro}
                          onChange={(e) =>
                            setForm({ ...form, logradouro: e.target.value })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mb-2">
                  <div className="col-md-6 col-sm-12">
                    <div className="row">
                      <div className="col-md-4 text-md-right text-sm-center pr-0">
                        <label htmlFor="numero">Número</label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          id="numero"
                          className="form-control"
                          value={form.numero}
                          onChange={(e) =>
                            setForm({
                              ...form,
                              numero: e.target.value?.replace(/\D/, ''),
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="row">
                      <div className="col-md-4 text-md-right text-sm-center pr-0">
                        <label htmlFor="bairro">Bairro</label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          id="bairro"
                          className="form-control"
                          value={form.bairro}
                          onChange={(e) =>
                            setForm({ ...form, bairro: e.target.value })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mb-2">
                  <div className="col-md-6 col-sm-12">
                    <div className="row">
                      <div className="col-md-4 text-md-right text-sm-center pr-0">
                        <label htmlFor="razao-social">CEP</label>
                      </div>
                      <div className="col-md-8">
                        {/* <input type="text" className="form-control" /> */}
                        <InputCEP
                          value={form.cep}
                          onChange={(value) => setForm({ ...form, cep: value })}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="row">
                      <div className="col-md-4 text-md-right text-sm-center pr-0">
                        <label htmlFor="complemento">Complemento</label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          id="complemento"
                          className="form-control"
                          value={form.complemento}
                          onChange={(e) =>
                            setForm({ ...form, complemento: e.target.value })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mb-2">
                  <div className="col-md-6 col-sm-12">
                    <div className="row">
                      <div className="col-md-4 text-md-right text-sm-center pr-0">
                        <label htmlFor="estado">Estado (UF)</label>
                      </div>
                      <div className="col-md-8">
                        <SelectSingle
                          value={form.uf}
                          name="estado"
                          obrigatorio={!form.uf}
                          onChange={(value) => {
                            setForm({
                              ...form,
                              uf: value,
                              cidadeId: '',
                            });
                            changeListCidade(value);
                          }}
                          options={listEstado || []}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="row">
                      <div className="col-md-4 text-md-right text-sm-center pr-0">
                        <label htmlFor="cidade">Cidade</label>
                      </div>
                      <div className="col-md-8">
                        <SelectSingle
                          value={form.cidadeId}
                          obrigatorio={!form.cidadeId}
                          name="cidade"
                          onChange={(value) => {
                            setForm({ ...form, cidadeId: value });
                          }}
                          options={listCidade || []}
                          disabled={!form.uf}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="offset-md-2 p-3">
                  <Button
                    onClick={() => addLoja(form)}
                    variant="primary"
                    className="pl-5 pr-5 btn-lg"
                    type="submit"
                  >
                    <FontAwesomeIcon icon={faSave} /> Salvar
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-sm-6 text-left">
              <button
                className="btn btn-primary btn-navigation"
                type="button"
                onClick={() => {
                  setActiveTab('cliente');
                }}
              >
                <span>
                  <FaAngleLeft size={26} />
                </span>
                <span>VOLTAR</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdicionarCliente;
