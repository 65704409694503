import React from 'react';

import './styles.scss';
// import loaderGif from 'assets/loader/loading_logo_color.gif';

const Loader = () => {
  return (
    <div className="loader-gif">
      <div>
        {/* <img src={loaderGif} alt="loading..." /> */}
        <div className="la-ball-scale-ripple-multiple">
          <div />
          <div />
          <div />
        </div>
      </div>
    </div>
  );
};

export default Loader;
