import React from 'react';

import HeaderApp from 'components/Header/HeaderApp';
import Bottom from 'components/Bottom';
import ContentSolicitacaoFluxoAprovarOrcamento from './ContentSolicitacaoFluxoAprovarOrcamento';

const SolicitacaoFluxoAprovarOrcamento = () => {
  return (
    <>
      <HeaderApp />
      <ContentSolicitacaoFluxoAprovarOrcamento />
      <Bottom />
    </>
  );
};

export default SolicitacaoFluxoAprovarOrcamento;
