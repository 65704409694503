/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import { compareString } from '../../../../_constants';

const BuscaProdutos = (props) => {
  const entities = props.entities;
  const solicitacaoProdutos = props.solicitacaoProdutos;
  const adicionarProduto = props.adicionarProduto;

  const [categoriaId, setCategoriaId] = useState();
  const [marcaId, setMarcaId] = useState();
  const [codigoNome, setCodigoNome] = useState('');

  const categorias = entities.categorias;

  const marcas = [];

  categorias.forEach((categoria) => {
    categoria.produtoCategoriaFilhas.forEach((marca) => {
      marcas.push(marca);
    });
  });

  const selecListCategorias = categorias.map((categoria) => {
    return {
      value: categoria.produtoCategoriaId,
      label: categoria.nome,
    };
  });

  const selecListMarcas = [];

  if (categoriaId) {
    let categoria = categorias.find(
      (categoria) => categoria.produtoCategoriaId === categoriaId
    );

    if (categoria) {
      categoria.produtoCategoriaFilhas.forEach((marca) => {
        selecListMarcas.push({
          value: marca.produtoCategoriaId,
          label: marca.nome,
        });
      });
    }
  }

  let produtos = (entities.produtos || []).filter((produto) => {
    if (solicitacaoProdutos.length > 0) {
      if (
        solicitacaoProdutos.findIndex(
          (sp) => sp.produtoId === produto.produtoId
        ) !== -1
      )
        return false;
    }
    if (codigoNome.length > 0)
      return (
        compareString(produto.nome || '', codigoNome) ||
        compareString(produto.ean || '', codigoNome)
      );
    if (marcaId && marcaId > 0) {
      return produto.produtoCategoriaId === marcaId;
    } else if (categoriaId && categoriaId > 0) {
      return (
        selecListMarcas.findIndex(
          (marca) => marca.value === produto.produtoCategoriaId
        ) !== -1
      );
    }
    return true;
  });

  const onChangeCategoria = (value) => {
    setMarcaId(null);
    setCategoriaId(value);
  };

  const onChangeMarca = (value) => {
    setMarcaId(value);
  };

  useEffect(() => {
    searchProdutos();
  }, [categoriaId, marcaId, codigoNome]);

  const searchProdutos = () => {};

  const renderFiltro = () => {
    return (
      <div className="row mt-3 mb-3">
        <div className="col-lg-6">
          <div className="mb-4">
            <label>Categoria</label>
            <Select
              options={selecListCategorias}
              isClearable
              onChange={(item) => onChangeCategoria(item?.value)}
              value={
                selecListCategorias.find(
                  (item) => item.value === categoriaId
                ) || ''
              }
            />
          </div>

          {selecListMarcas.length && selecListMarcas.length > 0 ? (
            <div className="mb-4">
              <label>Marca</label>
              <Select
                options={selecListMarcas}
                isClearable
                onChange={(item) => onChangeMarca(item?.value)}
                value={
                  selecListMarcas.find((item) => item.value === marcaId) || ''
                }
              />
            </div>
          ) : null}

          <div className="mb-4">
            <label>EAN ou Nome do produto</label>
            <input
              type="text"
              className="form-control"
              value={codigoNome || ''}
              onChange={(event) => setCodigoNome(event.target.value)}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderProdutos = () => {
    if (produtos.length === 0) {
      return <p>Nenhum produto encontrado.</p>;
    }

    return (
      <div className="table-search-produtos-wrapper">
        <table className="table table-striped table-search-produtos">
          <thead>
            <tr>
              <th>EAN</th>
              <th>Nome</th>
              <th>Marca</th>
              <th>Categoria</th>
              <th>Grupo</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {produtos.map((produto, index) => {
              return (
                <tr key={index}>
                  <td>{produto.ean}</td>
                  <td>{produto.nome}</td>
                  <td>{produto.marca}</td>
                  <td>{produto.categoria}</td>
                  <td>{produto.grupo}</td>
                  <td>
                    <button
                      className="btn btn-primary btn-navigation btn-navigation-xs"
                      onClick={() => adicionarProduto(produto)}
                    >
                      <span>+ Adicionar</span>
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div>
      {renderFiltro()}

      {renderProdutos()}
    </div>
  );
};

export default BuscaProdutos;
