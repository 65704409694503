import { GET_SELECT_LIST } from 'services/MaterialComunicacao';

const materialComunicacao = () => {
  async function getMaterialComunicacaoSelectList() {
    const result = await GET_SELECT_LIST();
    return result.value || [];
  }

  return {
    getMaterialComunicacaoSelectList,
  };
};

export default materialComunicacao;
