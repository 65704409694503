/* eslint-disable react/self-closing-comp */
/* eslint-disable object-shorthand */
/* eslint-disable prefer-const */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable prefer-destructuring */
import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';

import { GET_PEDIDO_TIPO_SOLICITACAO } from 'services/Pedido';
import Summary from '../Summary';

import './styles.scss';

const ModalSummary = (props) => {
  let solicitacaoId = props.solicitacaoId;
  let handleSolicitarNovamente = props.handleSolicitarNovamente;
  let displayButtonSolicitarNovamente = props.displayButtonSolicitarNovamente;
  const [tipoPedido, setTipoPedido] = useState('');

  const [isOwner, setIsOwner] = useState(false);
  const updateIsOwner = (value) => {
    setIsOwner(value);
  };

  const handleClose = () => {
    props.onClose();
    solicitacaoId = 0;
  };

  const show = () => {
    return solicitacaoId > 0;
  };

  useEffect(() => {
    (async () => {
      const tipo = await GET_PEDIDO_TIPO_SOLICITACAO(solicitacaoId);
      setTipoPedido(tipo);
    })();
  }, [solicitacaoId]);

  return (
    <Modal show={show()} onHide={handleClose} className="modal-summary">
      <Modal.Header closeButton className="header">
        <Modal.Title>Solicitação {show() && solicitacaoId}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Summary solicitacaoId={solicitacaoId} updateIsOwner={updateIsOwner} />
      </Modal.Body>
      <Modal.Footer className="modal-footer">
        {displayButtonSolicitarNovamente && isOwner && !tipoPedido.arteFacil && (
          <Button
            variant="primary"
            onClick={() => {
              handleSolicitarNovamente(solicitacaoId);
            }}
            className="button-close"
          >
            SOLICITAR NOVAMENTE
          </Button>
        )}

        <Button
          variant="primary"
          onClick={handleClose}
          className="button-close"
        >
          FECHAR
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalSummary;
