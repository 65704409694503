/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { FaSearch, FaAngleRight } from 'react-icons/fa';
import { Modal, Button } from 'react-bootstrap';
import Select, { components, createFilter } from 'react-select';
import { optimizeSelect } from 'components/_base/OptimizeSelect';

//import './styles.scss';
import api from 'api';

import { getCampoObrigatorioClassName } from '../../../_constants';

import InputCEP from 'components/_base/InputCEP';
import InputTelefone from 'components/_base/InputTelefone';

const Outro = (props) => {
  let model = props.model;
  let entities = props.entities;
  let setModel = props.setModel;

  model.entregaOutro = model.entregaOutro || {};

  const [entregaOutro, setEntregaOutro] = useState({});
  useEffect(() => {
    setEntregaOutro(model.entregaOutro);
  }, [model])

  const [selectListEstados, setSelectListEstados] = useState([]);
  useEffect(() => {
    if (selectListEstados.length)
      return;

    let lista = entities.estados.map((estado) => {
      return {
        value: estado.uf,
        label: estado.nome,
      };
    })
    setSelectListEstados(lista);
  }, [entities])

  const [selectListCidades, setSelectListCidades] = useState({});
  useEffect(() => {
    if (selectListCidades.uf && selectListCidades.uf === entregaOutro.uf)
      return;

    let lista = (entities.estados.find((x) => x.uf === entregaOutro.uf)?.cidades || []).map((cidade) => {
      return {
        value: cidade.cidadeId,
        label: cidade.nome,
      };
    });

    setSelectListCidades({
      uf: entregaOutro.uf,
      options: lista
    });
  }, [entregaOutro, selectListEstados])

  const [selectedCidade, setSelectedCidade] = useState({});
  useEffect(() => {
    if (selectedCidade?.value && selectedCidade?.value === entregaOutro.cidadeId)
      return;

    setSelectedCidade(
      (selectListCidades.options || []).find(
        (item) => item.value === entregaOutro.cidadeId
      ) || null
    );
  }, [entregaOutro, selectListCidades]);

  const updateCidade = () => {
    entregaOutro.cidade = (
      (entities.estados.find((x) => x.uf === entregaOutro.uf) || {}).cidades || []
    ).find((x) => x.cidadeId === entregaOutro.cidadeId);

    updateEntregaOutro()
  };

  const updateEntregaOutro = () => {
    model.entregaOutro = {...entregaOutro};
    setEntregaOutro(model.entregaOutro);
  };

  return (
    <div className="entrega-fisica-panel">
      <div className="row">
        <div className="col-lg-4">
          <div>
            <label>CEP</label>
            <InputCEP
              className={`form-control ${getCampoObrigatorioClassName(
                entregaOutro.cep,
                true
              )}`}
              value={entregaOutro.cep || ''}
              onChange={(value) => {
                entregaOutro.cep = value;
                updateEntregaOutro();
              }}
            />
          </div>

          <div>
            <label>Endereço</label>
            <input
              type="text"
              className={`form-control ${getCampoObrigatorioClassName(
                entregaOutro.logradouro,
                true
              )}`}
              value={entregaOutro.logradouro || ''}
              onChange={(event) => {
                entregaOutro.logradouro = event.target.value;
                updateEntregaOutro();
              }}
            />
          </div>

          <div>
            <label>Número</label>
            <input
              type="text"
              className={`form-control ${getCampoObrigatorioClassName(
                entregaOutro.numero,
                true
              )}`}
              value={entregaOutro.numero || ''}
              onChange={(event) => {
                entregaOutro.numero = event.target.value;
                updateEntregaOutro();
              }}
            />
          </div>

          <div>
            <label>Complemento</label>
            <input
              type="text"
              className="form-control"
              value={entregaOutro.complemento || ''}
              onChange={(event) => {
                entregaOutro.complemento = event.target.value;
                updateEntregaOutro();
              }}
            />
          </div>

          <div>
            <label>Bairro</label>
            <input
              type="text"
              className={`form-control ${getCampoObrigatorioClassName(
                entregaOutro.bairro,
                true
              )}`}
              value={entregaOutro.bairro || ''}
              onChange={(event) => {
                entregaOutro.bairro = event.target.value;
                updateEntregaOutro();
              }}
            />
          </div>
        </div>

        <div className="col-lg-8">
          <div className="row">
            <div className="col-lg-6">
              <div>
                <label>Estado</label>
                <Select
                  className={`${getCampoObrigatorioClassName(
                    entregaOutro.uf,
                    true
                  )}`}
                  options={selectListEstados}
                  isClearable={true}
                  onChange={(item) => {
                    entregaOutro.uf = item?.value;
                    updateEntregaOutro();
                  }}
                  value={selectListEstados.find(
                    (item) => item.value === entregaOutro?.uf
                  )}
                />
              </div>

              <div>
                <label>Cidade</label>
                <Select
                  className={`${getCampoObrigatorioClassName(
                    entregaOutro.cidadeId,
                    true
                  )}`}
                  components={optimizeSelect.components}
                  filterOption={createFilter({ ignoreAccents: false })}
                  options={selectListCidades.options || []}
                  isClearable={true}
                  onChange={(item) => {
                    var cidadeId = item?.value ?? 0;
                    if (entregaOutro.cidadeId !== cidadeId) {
                      entregaOutro.cidadeId = cidadeId;
                      updateCidade();
                    }
                  }}
                  value={selectedCidade}
                  isDisabled={!entregaOutro?.uf}
                />
              </div>
            </div>

            <div className="col-lg-6">
              <div>
                <label>Razão Social</label>
                <input
                  type="text"
                  className={`form-control ${getCampoObrigatorioClassName(
                    entregaOutro.razaoSocial,
                    true
                  )}`}
                  value={entregaOutro.razaoSocial || ''}
                  onChange={(event) => {
                    entregaOutro.razaoSocial = event.target.value;
                    updateEntregaOutro();
                  }}
                />
              </div>

              <div>
                <label>CNPJ/CPF</label>
                <input
                  type="text"
                  className={`form-control ${getCampoObrigatorioClassName(
                    entregaOutro.cnpj,
                    true
                  )}`}
                  value={entregaOutro.cnpj || ''}
                  onChange={(event) => {
                    entregaOutro.cnpj = event.target.value;
                    updateEntregaOutro();
                  }}
                />
              </div>
            </div>
            <div />
          </div>

          <div className="row">
            <div className="col-lg-12">
              <hr />

              <h5>Dados do Responsável</h5>

              <div>
                <label>Nome</label>
                <input
                  type="text"
                  className={`form-control ${getCampoObrigatorioClassName(
                    entregaOutro.responsavelNome,
                    true
                  )}`}
                  value={entregaOutro.responsavelNome || ''}
                  onChange={(event) => {
                    entregaOutro.responsavelNome = event.target.value;
                    updateEntregaOutro();
                  }}
                />
              </div>
            </div>

            <div className="col-lg-6">
              <div>
                <label>Telefone</label>
                <InputTelefone
                  className={`${getCampoObrigatorioClassName(
                    entregaOutro.responsavelTelefone,
                    true
                  )}`}
                  value={entregaOutro.responsavelTelefone || ''}
                  onChange={(value) => {
                    entregaOutro.responsavelTelefone = value;
                    updateEntregaOutro();
                  }}
                />
              </div>
            </div>

            <div className="col-lg-6">
              <div>
                <label>E-mail</label>
                <input
                  type="text"
                  className={`form-control ${getCampoObrigatorioClassName(
                    entregaOutro.responsavelEmail,
                    true
                  )}`}
                  value={entregaOutro.responsavelEmail || ''}
                  onChange={(event) => {
                    entregaOutro.responsavelEmail = event.target.value;
                    updateEntregaOutro();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Outro;
