import React, { useState, useEffect, useContext } from 'react';
import { Carousel } from 'react-bootstrap';
import { Context } from 'context/AuthContext';

import { GET_LIST_BANNER_LOGIN } from 'services/BannerLogin';

const ContentLogin = () => {
  const [slides, setSlides] = useState([]);
  const { showLoader } = useContext(Context);

  useEffect(() => {
    (async () => {
      showLoader(true);
      const data = await GET_LIST_BANNER_LOGIN();
      setSlides(data.value);
      showLoader(false);
    })();
  }, []);

  return (
    <div className="content">
      <Carousel className="w-100">
        {slides?.map((item) => {
          return (
            <Carousel.Item key={item.ordem}>
              <img
                className="d-block w-100"
                src={item.urlImagem}
                alt={item.texto}
              />
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
};

export default ContentLogin;
