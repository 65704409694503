/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

import './styles.scss';

import { tabs, entregaTabs, passos } from './../_constants';

import api from 'api';

import EntregaFisica from './EntregaFisica';
import EntregaDigital from './EntregaDigital';

const Entrega = (props) => {
  const params = useParams();
  const history = useHistory();
  const location = useLocation();

  let baseUrl = props.baseUrl;
  let updateRascunho = props.updateRascunho;
  let model = props.model;
  let entities = props.entities;
  let estados = props.estados;
  let setModel = props.setModel;
  let renderErrors = props.renderErrors;
  let errors = (model.errors || []).filter(
    (error) => error.passo === passos.passoEntrega.nro
  );
  let tipoMateriais = entities.tipoMateriais || [];
  let solicitacoes = model.solicitacoes || [];
  let setActiveTab = props.setActiveTab;

  const existeMaterialComProducao = () => {
    //todo: instanciar tipoMateriais se null
    if (!tipoMateriais.length) return false;

    let materiais = [].concat(
      ...tipoMateriais.map((tipoMaterial) => tipoMaterial.materiaisComunicacao)
    );

    let selecionados = solicitacoes.map((solicitacao) =>
      materiais.find(
        (material) =>
          material.materialComunicacaoId === solicitacao.materialComunicacaoId
      )
    );

    return selecionados.findIndex((material) => material.possuiProducao) !== -1;
  };

  const initialTab = existeMaterialComProducao()
    ? entregaTabs.tabFisica
    : entregaTabs.tabDigital;
  const currentTab = location.pathname.split('/')[4] || initialTab;
  const [entregaActiveTab, setEntregaActiveTab] = useState(currentTab);

  const changeTab = (entregaTab) => {
    history.push(
      `${baseUrl}/${model.rascunhoId}/${tabs.tabEntrega}/${entregaTab}`
    );
  };

  useEffect(() => {
    return history.listen((location) => {
      let tab = location.pathname.split('/')[4] || initialTab;
      setEntregaActiveTab(tab);
    });
  }, [history]);

  useEffect(() => {
    var array = location.pathname.split('/');
    if (model.rascunhoId > 0 && solicitacoes.length > 0 && array.length === 4) {
      history.push(
        `${baseUrl}/${model.rascunhoId}/${tabs.tabEntrega}/${initialTab}`
      );
    }
  }, [model]);

  const renderTabs = () => {
    if (existeMaterialComProducao()) {
      if (entregaActiveTab === entregaTabs.tabFisica)
        return (
          <EntregaFisica
            model={model}
            entities={entities}
            estados={estados}
            setModel={setModel}
            avancarEntregaDigital={() => {
              updateRascunho();
              changeTab(entregaTabs.tabDigital);
            }}
            avancarSolicitacoes={() => {
              updateRascunho();
              setActiveTab(tabs.tabSolicitacoes);
            }}
            voltar={() => {
              updateRascunho();
              setActiveTab(tabs.tabMateriais);
            }}
            errors={errors}
            renderErrors={renderErrors}
          />
        );

      if (entregaActiveTab === entregaTabs.tabDigital)
        return (
          <EntregaDigital
            model={model}
            entities={entities}
            setModel={setModel}
            avancar={() => {
              updateRascunho();
              setActiveTab(tabs.tabSolicitacoes);
            }}
            voltar={() => {
              updateRascunho();
              changeTab(entregaTabs.tabFisica);
            }}
            errors={errors}
            renderErrors={renderErrors}
          />
        );
    } else {
      if (entregaActiveTab === entregaTabs.tabDigital)
        return (
          <EntregaDigital
            model={model}
            entities={entities}
            setModel={setModel}
            avancar={() => {
              updateRascunho();
              setActiveTab(tabs.tabSolicitacoes);
            }}
            voltar={() => {
              updateRascunho();
              setActiveTab(tabs.tabMateriais);
            }}
            errors={errors}
            renderErrors={renderErrors}
          />
        );
    }

    return null;
  };

  return <div className="wrapper-content-entrega">{renderTabs()}</div>;
};

export default Entrega;
