import { GET_SELECT_ETAPA } from 'services/Etapa';

const etapa = () => {
  async function getEtapaSelectList() {
    const data = await GET_SELECT_ETAPA();
    return data.value || [];
  }

  return {
    getEtapaSelectList,
  };
};

export default etapa;
