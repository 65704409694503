/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { UncontrolledCollapse } from 'reactstrap';
import { FormGroup, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faEraser, faPlus } from '@fortawesome/free-solid-svg-icons';
import { GET_BANCO_IMAGEM_CATEGORIAS } from 'services/BancoImagem';
import {
  GET_LIST_MARCA,
  GET_LIST_CATEGORIA,
} from 'services/ProdutoCategoria';

import './styles.scss';

const Filter = (props) => {
  const [marcas, setMarcas] = useState([]);
  const [marcasSelecionadas, setMarcasSelecionadas] = useState([]);

  const [categorias, setCategorias] = useState([]);
  const [categoriasFiltradas, setCategoriasFiltradas] = useState([]);
  const [categoriasSelecionadas, setCategoriasSelecionadas] = useState([]);

  const [categoriaImagens, setCategoriaImagens] = useState([]);
  const [categoriaImagensSelecionadas, setCategoriaImagensSelecionadas] =
    useState([]);

  const [texto, setTexto] = useState('');

  // ============================================================================================================================
  // Effects

  useEffect(() => {
    let items = [];

    if (!marcasSelecionadas?.length || marcasSelecionadas.length === 0) {
      items = categorias;
    } else {
      items = categorias.filter(
        (c) =>
          marcasSelecionadas.findIndex(
            (marcaId) => marcaId === c.produtoCategoriaSuperiorId
          ) !== -1
      );
    }

    const distinctByName = [];
    for (var i = 0; i < items.length; i++) {
      const index = distinctByName.findIndex((x) => x.nome === items[i].nome);
      if (index === -1) {
        let produtoCategoria = {
          ...items[i],
          categoriaIds: [items[i].produtoCategoriaId],
        };

        produtoCategoria.checked = categoriasFiltradas.find(
          (c) =>
            c.categoriaIds.findIndex(
              (produtoCategoriaId) =>
                produtoCategoriaId === produtoCategoria.produtoCategoriaId
            ) !== -1
        )?.checked;

        distinctByName.push(produtoCategoria);
      } else {
        let produtoCategoria = items[i];

        distinctByName[index].checked =
          distinctByName[index].checked ||
          categoriasFiltradas.find(
            (c) =>
              c.categoriaIds.findIndex(
                (produtoCategoriaId) =>
                  produtoCategoriaId === produtoCategoria.produtoCategoriaId
              ) !== -1
          )?.checked;

        distinctByName[index].categoriaIds.push(
          produtoCategoria.produtoCategoriaId
        );
      }
    }

    setCategoriasFiltradas(distinctByName);

    updateCategoriasSelecionadas(distinctByName);

  }, [categorias, marcasSelecionadas]);

  // ----------------------------------------------------------------------------------------------------------------------------
  useEffect(() => {
    (async () => {
      const data = await GET_BANCO_IMAGEM_CATEGORIAS();
      setCategoriaImagens(data.value);
    })();

    (async () => {
      const listaMarca = await GET_LIST_MARCA();
      setMarcas(listaMarca.value);

      const listaCategorias = await GET_LIST_CATEGORIA();
      setCategorias(listaCategorias.value);
    })();
  }, []);

  // ============================================================================================================================
  // Event handlers

  function onChangeTexto(event) {
    const { value, name } = event.target;
    setTexto(value);
  }

  // ----------------------------------------------------------------------------------------------------------------------------
  const onChangeCategoriaImagens = (event) => {
    let catImagens = categoriaImagensSelecionadas;
    if (event.target.checked) {
      const item = catImagens.find(
        (item) => item === parseFloat(event.target.value)
      );

      if (!item) {
        catImagens.push(parseFloat(event.target.value));
      }
    } else {
      const item = catImagens.find(
        (item) => item === parseFloat(event.target.value)
      );
      const indexItem = catImagens.findIndex(
        (item) => item === parseFloat(event.target.value)
      );

      if (item) {
        catImagens.splice(indexItem);
      }
    }

    setCategoriaImagensSelecionadas(catImagens);
  };

  // ----------------------------------------------------------------------------------------------------------------------------
  const onChangeMarcas = (event) => {
    const elementValue = parseInt(event.target.value);
    const $element = event.target;
    let marcas = marcasSelecionadas;
    let items = [];

    if ($element.checked) {
      const itemMarca = marcas.find((marca) => marca === elementValue);

      if (!itemMarca) {
        marcas.push(elementValue);
      }
      setMarcasSelecionadas([...marcas]);
    } else {
      let newMarcas = [];

      for (let i = 0; i < marcas.length; i++) {
        if (marcas[i] !== elementValue) {
          newMarcas.push(marcas[i]);
        }
      }

      setMarcasSelecionadas(newMarcas);
    }
  };

  // ----------------------------------------------------------------------------------------------------------------------------
  const onChangeCategoriaProdutos = (event) => {
    const elementValue = parseInt(event.target.value);

    const item = categoriasFiltradas.find(
      (categoria) => categoria.produtoCategoriaId === elementValue
    );
    item.checked = event.target.checked;

    updateCategoriasSelecionadas(categoriasFiltradas);
  };

  const updateCategoriasSelecionadas = (categoriasFiltradas) => {
    const itensChecados = [].concat(
      ...categoriasFiltradas.filter((x) => x.checked).map((x) => x.categoriaIds)
    );

    setCategoriasSelecionadas(itensChecados);
  }

  // ----------------------------------------------------------------------------------------------------------------------------
  const filterImages = () => {
    props.filtrar(
      texto,
      marcasSelecionadas,
      categoriasSelecionadas,
      categoriaImagensSelecionadas
    );
  };

  // ----------------------------------------------------------------------------------------------------------------------------
  const clearFilter = () => {
    setCategoriaImagensSelecionadas([]);
    setCategoriasSelecionadas([]);
    setMarcasSelecionadas([]);
    setTexto('');

    const items = document.getElementsByTagName('INPUT');

    for (let loop = 0; loop < items.length; loop++) {
      let item = items[loop];
      if (item.type === 'checkbox' && item.checked) {
        item.checked = false;
      }
    }

    props.filtrar('', [], [], [], []);
  };

  // ============================================================================================================================
  // Render

  return (
    <div className="filter-body">
      <div className="titulo">FILTROS</div>
      <div className="body">
        <div className="input">
          <label>Nome ou Código</label>
          <input
            type="text"
            className="form-control"
            onChange={onChangeTexto}
            value={texto}
          />
        </div>

        <hr className="default" />

        <div className="filters">
          <label type="button" className="btn btn-block" id="itensCat">
            Categoria Imagem{' '}
            <FontAwesomeIcon
              className="float-right"
              style={{ marginLeft: '10px' }}
              icon={faPlus}
            />
          </label>
          <UncontrolledCollapse toggler="#itensCat">
            <div>
              {categoriaImagens.map((categoria) => (
                <div key={categoria.bancoImagemCategoriaId}>
                  <label style={{ fontSize: '14px' }}>
                    <input
                      onChange={onChangeCategoriaImagens}
                      type="checkbox"
                      value={categoria.bancoImagemCategoriaId}
                    />{' '}
                    {categoria.nome}
                  </label>
                </div>
              ))}
            </div>
          </UncontrolledCollapse>
        </div>

        <hr className="default" />

        <div className="filters">
          <label type="button" className="btn btn-block" id="itensMarca">
            Marca <FontAwesomeIcon className="float-right" icon={faPlus} />
          </label>
          <UncontrolledCollapse toggler="#itensMarca">
            <div>
              {marcas.map((marca) => {
                return (
                  <div key={marca.produtoCategoriaId}>
                    <label style={{ fontSize: '14px' }}>
                      <input
                        onChange={onChangeMarcas}
                        value={marca.produtoCategoriaId}
                        type="checkbox"
                      />{' '}
                      {marca.nome}
                    </label>
                  </div>
                );
              })}
            </div>
          </UncontrolledCollapse>
        </div>

        <hr className="default" />
        <div className="filters">
          <label type="button" className="btn btn-block" id="itensProd">
            Categoria <FontAwesomeIcon className="float-right" icon={faPlus} />
          </label>

          <UncontrolledCollapse toggler="#itensProd">
            <div>
              {categoriasFiltradas.map((item) => (
                <div key={`${item.produtoCategoriaId}`}>
                  <label style={{ fontSize: '14px' }}>
                    <input
                      onChange={onChangeCategoriaProdutos}
                      type="checkbox"
                      value={item.produtoCategoriaId}
                      checked={item.checked || false}
                    />{' '}
                    {item.nome}
                  </label>
                </div>
              ))}
            </div>
          </UncontrolledCollapse>
        </div>

        <hr className="default" />

        <div className="buttons">
          <FormGroup className="row">
            <div className=" col-lg-12">
              <Button
                onClick={filterImages}
                className="query-button"
                variant="primary"
              >
                <FontAwesomeIcon icon={faSearch} /> Filtrar
              </Button>
              <Button
                onClick={clearFilter}
                className="query-button"
                variant="outline-primary"
              >
                <FontAwesomeIcon icon={faEraser} /> Limpar
              </Button>
            </div>
          </FormGroup>
        </div>
      </div>
    </div>
  );
};

export default Filter;
