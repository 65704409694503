import api from 'api';

const PATH = '/Usuario';

const UPDATE_FIREBASE_ANDROID_NOTIFICATION = async (bodyData) => {
  const { data } = await api.post(
    `${PATH}/update-firebase-android-notification-token`,
    bodyData
  );
  return data;
};
const UPDATE_FIREBASE_IOS_NOTIFICATION = async (bodyData) => {
  const { data } = await api.post(
    `${PATH}/update-firebase-ios-notification-token`,
    bodyData
  );
  return data;
};
const UPDATE_MEUS_DADOS = async (bodyData) => {
  const { data } = await api.post(`${PATH}/update-meus-dados`, bodyData);
  return data;
};
const UPDATE_SENHA = async (bodyData) => {
  const { data } = await api.post(`${PATH}/update-senha`, bodyData);
  return data;
};
const UPDATE_DADOS_CADASTRO_USUARIO = async (bodyData) => {
  const { data } = await api.post(
    `${PATH}/update-dados-cadastro-usuario`,
    bodyData
  );
  return data;
};
const GET_USUARIO = async (id) => {
  const { data } = await api.get(`${PATH}/get-usuario/${id}`);
  return data;
};
const GET_LIST_USUARIO_TIPO = async (filtros) => {
  const { data } = await api.get(`${PATH}/get-list-usuario-tipo`, {
    params: filtros,
  });
  return data;
};
const GET_LIST_USUARIO_SUPERIOR = async (filtros) => {
  const { data } = await api.get(`${PATH}/get-list-usuario-superior`, {
    params: filtros,
  });
  return data;
};
const POST_NOVA_SENHA = async (bodyData) => {
  const { data } = await api.post(`${PATH}/post-nova-senha`, bodyData);
  return data;
};
const POST_RECUPERAR_SENHA = async (bodyData) => {
  const { data } = await api.post(`${PATH}/post-recuperar-senha`, bodyData);
  return data;
};

export {
  GET_LIST_USUARIO_SUPERIOR,
  GET_LIST_USUARIO_TIPO,
  GET_USUARIO,
  UPDATE_DADOS_CADASTRO_USUARIO,
  UPDATE_SENHA,
  UPDATE_FIREBASE_ANDROID_NOTIFICATION,
  UPDATE_FIREBASE_IOS_NOTIFICATION,
  UPDATE_MEUS_DADOS,
  POST_NOVA_SENHA,
  POST_RECUPERAR_SENHA,
};
