import React from 'react';
import PageTitle from '../PageTitle';

import './styles.scss';

const TitlePages = ({ title }) => {
  return (
    <div className="title-pages">
      <PageTitle title={title} />
      <hr />
    </div>
  );
};

export default TitlePages;
