import React from 'react';
import { useSelector } from 'react-redux';

import Routes from './routes';
import Loader from './components/_base/Loader';

const App = () => {
  const loader = useSelector((state) => state.loader);
  return (
    <>
      {loader.loading === true ? <Loader /> : null}
      <Routes />
    </>
  );
};

export default App;
