// import api from 'api';
import { GET_SELECT_LIST_CHAMADO_TIPO } from 'services/Chamado';

const motivo = () => {
  async function getMotivoSelectList() {
    const result = await GET_SELECT_LIST_CHAMADO_TIPO();
    return result.value || [];
  }

  return {
    getMotivoSelectList,
  };
};

export default motivo;
