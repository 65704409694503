/* eslint-disable */
import React, { useState, useContext, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import TitlePages from 'components/_base/TitlePages';

import './styles.scss';
import api from 'api';
import { Context } from 'context/AuthContext';
import ContentPendencias from '../ContentPendencias';
import ContentDemandas from '../ContentDemandas';

import { GET_USUARIO } from 'services/Usuario';

const ContentPendenciaDemanda = () => {
  const [activeButtom, setActiveButtom] = useState('pendencias');
  const { showLoader } = useContext(Context);
  const userAuth = JSON.parse(localStorage.getItem('midias_camil_site'));
  const [usuario, setUsuario] = useState({});
  const [demandaDisabled, setDemandaDisanabled] = useState(true);

  useEffect(() => {
    (async () => {
      showLoader(true);

      const data = await GET_USUARIO(userAuth.userId);
      setUsuario({
        usuarioId: data.value.usuarioId,
        nome: data.value.nome,
        email: data.value.email,
        telefone: data.value.telefone,
        regionalId: data.value.regionalId,
        usuarioTipoId: data.value.usuarioTipoId,
      });
      if (data.value.usuarioTipo?.sigla !== "DIAG") {
        setDemandaDisanabled(true);
      }else{
        setDemandaDisanabled(false);
      }
      showLoader(false);
    })();
  }, []);

  return (
    <div className="content-pendencia-demenda">
      {demandaDisabled && <TitlePages title="Pendências" />}
      {!demandaDisabled && (
        <div className="Selector">
          <Row>
            <Col></Col>
            <Col>
              {activeButtom === 'pendencias' ? (
                <Button size="lg" variant="primary" block>
                  PENDÊNCIAS
                </Button>
              ) : (
                <Button
                  size="lg"
                  variant="outline-primary"
                  onClick={() => {
                    setActiveButtom('pendencias');
                  }}
                  block
                >
                  PENDÊNCIAS
                </Button>
              )}
            </Col>
            <Col></Col>
            <Col>
              {activeButtom === 'demandas' ? (
                <Button
                  size="lg"
                  variant="primary"
                  block
                  disabled={demandaDisabled}
                >
                  DEMANDAS
                </Button>
              ) : (
                <Button
                  size="lg"
                  variant="outline-primary"
                  onClick={() => {
                    setActiveButtom('demandas');
                  }}
                  block
                  disabled={demandaDisabled}
                >
                  DEMANDAS
                </Button>
              )}
            </Col>
            <Col></Col>
          </Row>

          <hr />
        </div>
      )}
      {/*<div className="text-center demanda-help-link">
        <span>
          <FontAwesomeIcon icon={faQuestionCircle} />
          &nbsp; Se tiver dúvidas para preencher essa página,{' '}
          <a href="#">CLIQUE AQUI</a>
        </span>
</div>*/}
      {activeButtom === 'pendencias' ? (
        <ContentPendencias />
      ) : (
        <ContentDemandas />
      )}
    </div>
  );
};

export default ContentPendenciaDemanda;
