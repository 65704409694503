// import api, { apiFormData } from 'api';
import api from 'api';

const PATH = '/Chamado';

const ADD_MENSAGEM = async (bodyData) => {
  const { data } = await api.post(`${PATH}/add-mensagem`, bodyData);
  return data;
};
const UPDATE_FECHAR_CHAMADO = async (id) => {
  const { data } = await api.post(`${PATH}/update-fechar-chamado/${id}`);
  return data;
};
const ADD_CHAMADO = async (formData) => {
  const { data } = await api.post(`${PATH}/add-chamado`, formData, {
    headers: {
      // eslint-disable-next-line no-underscore-dangle
      accept: 'text/plain',
      'Content-Type': 'multipart/form-data',
    },
  });
  return data;
};
const GET_CHAMADO = async (id) => {
  const { data } = await api.get(`${PATH}/get-chamado/${id}`);
  return data;
};
// /Chamado/get-list-chamado-por-usuario/{usuarioId}
const GET_CHAMADO_USER_ID = async (usuarioId) => {
  const { data } = await api.get(
    `${PATH}/get-list-chamado-por-usuario/${usuarioId}`
  );
  return data;
};
const GET_LIST_CHAMADO_POR_USUARIO = async (usuarioId) => {
  const { data } = await api.get(
    `${PATH}/get-list-chamado-por-usuario/${usuarioId}`
  );
  return data;
};
const GET_LIST_CHAMADO_TIPO = async () => {
  const { data } = await api.get(`${PATH}/get-list-chamado-tipo`);
  return data;
};
const GET_SELECT_LIST_CHAMADO_TIPO = async () => {
  const { data } = await api.get(`${PATH}/get-select-list-chamado-tipo`);
  return data;
};

export {
  ADD_MENSAGEM,
  GET_CHAMADO,
  UPDATE_FECHAR_CHAMADO,
  GET_LIST_CHAMADO_POR_USUARIO,
  ADD_CHAMADO,
  GET_LIST_CHAMADO_TIPO,
  GET_CHAMADO_USER_ID,
  GET_SELECT_LIST_CHAMADO_TIPO,
};
