/* eslint-disable prefer-const */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

import './styles.scss';

import InputTelefone from 'components/_base/InputTelefone';

const EntregaDigital = (props) => {
  let solicitacao = props.solicitacao;
  let entities = props.entities;

  solicitacao.entregaDigital = solicitacao.entregaDigital || {};
  const [entregaDigital, setEntregaDigital] = useState(
    solicitacao.entregaDigital || {}
  );

  const updateEntregaDigital = (obj) => {
    solicitacao.entregaDigital = { ...obj };
    setEntregaDigital(solicitacao.entregaDigital);
  };

  useEffect(() => {
    setEntregaDigital(solicitacao.entregaDigital || {});
  }, [solicitacao]);

  const entregaTipoDigital = (entities.tipoEntregas || []).find(
    (tipoEntrega) => {
      return tipoEntrega.entregaDigital;
    }
  );

  // const avancarSolicitacoes = () => {
  //   solicitacao.solicitacoes.forEach((solicitacao) => {
  //     if (!solicitacao.imprimir)
  //       solicitacao.entregaTipoId = entregaTipoDigital.entregaTipoId;
  //   })

  //   avancar();
  // }

  const renderFields = () => {
    if (!solicitacao.entregaDigital) return null;

    return (
      <div className="row mb-5">
        <div className="col-lg-12 mb-3">
          <div>
            <label>Nome</label>
            <input
              type="text"
              className="form-control"
              value={entregaDigital.nome || ''}
              onChange={(event) => {
                entregaDigital.nome = event.target.value;
                updateEntregaDigital(entregaDigital);
              }}
            />
          </div>
        </div>

        <div className="col-lg-6">
          <div>
            <label>Telefone</label>
            <InputTelefone
              value={entregaDigital.telefone || ''}
              onChange={(value) => {
                entregaDigital.telefone = value;
                updateEntregaDigital(entregaDigital);
              }}
            />
          </div>
        </div>

        <div className="col-lg-6">
          <div>
            <label>E-mail</label>
            <input
              type="text"
              className="form-control"
              value={entregaDigital.email || ''}
              onChange={(event) => {
                entregaDigital.email = event.target.value;
                updateEntregaDigital(entregaDigital);
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="wrapper-content-alterar-entrega-digital">
      <div className="content-alterar-entrega-digital">
        <div className="row m-5">
          <div className="col-lg-12 text-center">
            <h5>Preencha os dados para envio da Arte Final.</h5>
          </div>
        </div>

        {renderFields()}
      </div>
    </div>
  );
};

export default EntregaDigital;
