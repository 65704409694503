/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { FaAngleRight } from 'react-icons/fa';
import Select from 'react-select';

import { passos } from '../../../_constants';

const Layout = ({ entities, solicitacao, avancar, renderErrors }) => {
  const errors = (solicitacao.errors || []).filter(
    (error) => error.passo === passos.passoLayout.nro
  );

  solicitacao.solicitacaoDados = solicitacao.solicitacaoDados || {};

  const [layoutArteId, setLayoutArteId] = useState(
    solicitacao.solicitacaoDados.layoutArteId || 0
  );

  const [layoutTipoId, setLayoutTipoId] = useState();
  const [marcaId, setMarcaId] = useState();

  const layoutsFiltroTipo = (entities.clienteLayouts.layoutArtes || []).filter(
    (layout) => {
      if (layoutTipoId && layout.layoutArteTipoId !== layoutTipoId)
        return false;
      return (
        layout.layoutArteMateriaisComunicacao.findIndex(
          (lm) => lm.materialComunicacaoId === solicitacao.materialComunicacaoId
        ) !== -1
      );
    }
  );

  const layoutsFiltroFinal = layoutsFiltroTipo.filter((layout) => {
    if (marcaId && layout.marcaId !== marcaId) return false;
    return true;
  });

  const selectListLayoutTipos = entities.layoutTipos.map((tipo) => {
    return {
      value: tipo.layoutArteTipoId,
      label: tipo.nome,
    };
  });

  const marcaIds = (layoutsFiltroTipo || []).map((layout) => layout.marcaId);
  const distinctMarcaIds = [...new Set(marcaIds)];

  const selectListMarcas = [];
  entities.categorias.forEach((categoria) => {
    categoria.produtoCategoriaFilhas.forEach((marca) => {
      if (!distinctMarcaIds.includes(marca.produtoCategoriaId)) return;
      selectListMarcas.push({
        value: marca.produtoCategoriaId,
        label: `${marca.nome} (${categoria.nome})`,
      });
    });
  });
  selectListMarcas.sort((a, b) => {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  });

  useEffect(() => {
    setLayoutArteId(solicitacao.solicitacaoDados.layoutArteId || 0);
  }, [solicitacao]);

  const layoutTipoOnChange = (id) => {
    setMarcaId(null);
    setLayoutTipoId(id);
  };

  const marcaOnChange = (id) => {
    setMarcaId(id);
  };

  // Filter render ---------------------------------------------------------------------------------------------------------
  const renderFilter = () => {
    return (
      <>
        <div className="row">
          <div className="col-lg-4">
            {/* Selecao Tipo Layout ------------------------------------------------------------------------------------------ */}
            <div className="col-lg-12">
              <div className="mb-4">
                <label>Tipo de Arte</label>
                <Select
                  options={selectListLayoutTipos}
                  isClearable
                  onChange={(item) => {
                    layoutTipoOnChange(item?.value);
                  }}
                  value={selectListLayoutTipos.find(
                    (item) => item.value === layoutTipoId
                  )}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            {/* Selecao Marca ------------------------------------------------------------------------------------------ */}
            <div className="col-lg-12">
              <div className="mb-4">
                <label>Marca</label>
                <Select
                  options={selectListMarcas}
                  isClearable
                  isDisabled={!layoutTipoId}
                  onChange={(item) => {
                    marcaOnChange(item?.value);
                  }}
                  value={
                    selectListMarcas.find((item) => item.value === marcaId) ||
                    ''
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderLayouts = () => {
    if (!layoutsFiltroFinal.length) return null;

    return (
      <div className="row">
        {layoutsFiltroFinal
          .sort(function (a, b) {
            return a.descricao.localeCompare(b.descricao);
          })
          .map((layout) => {
            return (
              <div key={layout.layoutArteId} className="col-lg-4 mg-3">
                <label>
                  <input
                    type="radio"
                    checked={layout.layoutArteId === layoutArteId}
                    onChange={() => {
                      solicitacao.solicitacaoDados.layoutArteId =
                        layout.layoutArteId;
                      setLayoutArteId(
                        solicitacao.solicitacaoDados.layoutArteId
                      );
                    }}
                  />{' '}
                  {layout.descricao}
                  <img
                    src={layout.urlImagem}
                    alt={layout.descricao}
                    className="img-fluid"
                  />
                </label>
              </div>
            );
          })}
      </div>
    );
  };

  return (
    <div>
      {renderErrors(errors)}

      {renderFilter()}

      {renderLayouts()}

      <div className="row mt-5">
        <div className="col-lg-12 d-flex flex-row-reverse">
          <button
            type="button"
            className="btn btn-primary btn-navigation btn-navigation-right"
            onClick={() => avancar()}
          >
            <span>AVANÇAR</span>
            <span>
              <FaAngleRight size={26} />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Layout;
