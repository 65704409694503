import React from 'react';

import HeaderApp from 'components/Header/HeaderApp';
import Bottom from 'components/Bottom';
import ContentSolicitacaoFluxoEnviarArte from './ContentSolicitacaoFluxoEnviarArte';

const SolicitacaoFluxoEnviarArte = () => {
  return (
    <>
      <HeaderApp />
      <ContentSolicitacaoFluxoEnviarArte />
      <Bottom />
    </>
  );
};

export default SolicitacaoFluxoEnviarArte;
