import React from 'react';

import Brand from 'components/_base/Brand';
import './styles.scss';

const HeaderLogoOnly = () => {
  return (
    <header className="header-logo-only">
      <div className="content-brand">
        <Brand white />
      </div>
    </header>
  );
};

export default HeaderLogoOnly;
