import React from 'react';

import Header from 'components/Header/HeaderApp';
import Bottom from 'components/Bottom';
import ContentDadosUsuario from './ContentDadosUsuario';

const DadosUsuario = () => {
  return (
    <>
      <Header />
      <ContentDadosUsuario />
      <Bottom />
    </>
  );
};

export default DadosUsuario;
