/* eslint-disable */
import React, { useEffect, useState, useContext } from 'react';

import { GET_PEDIDO_TIPO_MATERIAL } from 'services/Pedido';

import { Context } from 'context/AuthContext';

import HeaderApp from 'components/Header/HeaderApp';
import Bottom from 'components/Bottom';
import ContentPedido from './ContentPedido';

const PedidoMateriais = () => {
  const { showLoader } = useContext(Context);

  const [pedidoTipo, setPedidoTipo] = useState({});

  useEffect(() => {
    (async () => {
      showLoader(true);

      const result = await GET_PEDIDO_TIPO_MATERIAL();
      if (result?.value) setPedidoTipo(result.value);

      showLoader(false);
    })();
  }, []);

  if (!pedidoTipo.pedidoTipoId) return <></>;

  return (
    <>
      <HeaderApp />
      <ContentPedido
        pedidoTipo={pedidoTipo}
        pedidoDeCampanha={false}
        baseUrl="/pedido-materiais"
      />
      <Bottom />
    </>
  );
};

export default PedidoMateriais;
