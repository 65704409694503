import React from 'react';

import PageWithoutHeader from 'components/PageWithoutHeader';
import ContentNewPassword from '../_Content/ContentNewPassword';

const NewPassword = () => {
  return (
    <PageWithoutHeader title="REDEFINIR SENHA">
      <ContentNewPassword />
    </PageWithoutHeader>
  );
};

export default NewPassword;
