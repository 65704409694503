import React from 'react';

import Brand from 'components/_base/Brand';
import FormLogin from 'components/FormLogin';

import './styles.scss';

const HeaderLogin = () => {
  return (
    <header className="header-login">
      <div className="content-brand">
        <Brand white />
      </div>
      <div className="content-form-login">
        <FormLogin />
      </div>
    </header>
  );
};

export default HeaderLogin;
