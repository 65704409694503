import VMasker from 'vanilla-masker';

function PhoneMask(phone) {
  let phoneWithMask = phone;
  phoneWithMask = VMasker.toPattern(phoneWithMask, '99999999999999999999');

  if (phoneWithMask.length > 10) {
    phoneWithMask = VMasker.toPattern(phoneWithMask, '(99) 99999-9999');
  } else {
    phoneWithMask = VMasker.toPattern(phoneWithMask, '(99) 9999-9999');
  }
  return phoneWithMask;
}

function PhoneUnMask(phone) {
  return phone.replace(/\D+/g, '');
}

function PhoneValidate(phone) {
  const valid = !(phone.length < 10);
  return valid;
}

export { PhoneMask, PhoneUnMask, PhoneValidate };
